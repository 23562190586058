import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import db, { auth } from '../../backend/firebase/firebaseConfig';
import { collection, setDoc, query, where, getDocs, updateDoc, deleteDoc, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import '../../styles/global.css';

const ManageBranches = () => {

    const { language, translations } = useContext(LanguageContext);
    const t = translations[language].dashboard;
    const [branches, setBranches] = useState([]);


    const [newDepartmentData, setNewDepartmentData] = useState({
      departmentName: '',
      departmentEmail: '',
      street: '',
      houseNumber: '',
      postalCode: '',
      city: '',
      country: '',
      departmentPhoneNumber: ''
  });

  const [editingBranch, setEditingBranch] = useState(null);
  const [editingBranchData, setEditingBranchData] = useState({
      // Initialize with the same structure as newBranchData
  });

  const [selectedBranchId] = useState(null);

  const [popupState, setPopupState] = useState({
    selectedBranchId: null,
    showAddUserPopup: false,
    showAddAdminPopup: false,
    showAddDepartmentPopup: false,
    showRemoveUserPopup: false,
    showRemoveAdminPopup: false,
    showRemoveDepartmentPopup: false,
    newUserId: '',
    newAdminId: '',
    newDepartmentId: ''
});

useEffect(() => {
  const fetchBranches = async () => {
      const user = auth.currentUser;
      if (user) {
          // Query for branches where the user is an admin
          const adminQuery = query(collection(db, "branches"), where("branchAdmins", "array-contains", user.uid));
          const adminSnapshot = await getDocs(adminQuery);

          // Query for branches where the user is a regular user
          const userQuery = query(collection(db, "branches"), where("branchUsers", "array-contains", user.uid));
          const userSnapshot = await getDocs(userQuery);

          // Combine the results from both queries
          const combinedBranches = [...adminSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })), 
                                    ...userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))];

          // Remove duplicates if any (in case the user is both admin and regular user in the same branch)
          const uniqueBranches = Array.from(new Map(combinedBranches.map(branch => [branch['id'], branch])).values());

          setBranches(uniqueBranches);
      }
  };
  fetchBranches();
}, []);


const handleLeaveUserBranch = async (branchId) => {
    if (window.confirm(t.leaveBranchUserMessage)) {
      try {
          const branchRef = doc(db, "branches", branchId);
          const branchDoc = await getDoc(branchRef);
          if (branchDoc.exists()) {
              const updatedUsers = branchDoc.data().branchUsers.filter(uid => uid !== auth.currentUser.uid);
              await updateDoc(branchRef, { branchUsers: updatedUsers });
              // Update local state or re-fetch branches
          }
      } catch (error) {
          console.error("Error leaving branch: ", error);
      }
   }
  window.location.reload();
};


const handleLeaveAdminBranch = async (branchId) => {
  if (window.confirm(t.leaveBranchAdminMessage)) {
      try {
          const branchRef = doc(db, "branches", branchId);
          const branchDoc = await getDoc(branchRef);

          if (branchDoc.exists()) {
              const currentAdmins = branchDoc.data().branchAdmins;
              const updatedAdmins = currentAdmins.filter(uid => uid !== auth.currentUser.uid);

              if (updatedAdmins.length === 0) {
                  // If the current user is the only admin
                  alert(t.alertYouAreOnlyAdminInBranch);
              } else {
                  // If there are other admins, proceed with leaving
                  await updateDoc(branchRef, { branchAdmins: updatedAdmins });
                  // Update local state or re-fetch branches
              }
          }
      } catch (error) {
          console.error("Error leaving branch: ", error);
      }
  }
  window.location.reload();
};


const startEditBranch = async (branchId) => {
  try {
      const branchRef = doc(db, "branches", branchId);
      const branchDoc = await getDoc(branchRef);

      setPopupState({
        ...popupState,
        showAddUserPopup: false,
        showAddAdminPopup: false,
        showAddDepartmentPopup: false,
        showRemoveUserPopup: false,
        showRemoveAdminPopup: false,
        showRemoveDepartmentPopup: false
    });

      if (branchDoc.exists()) {
          setEditingBranch(branchId); // Set the ID of the branch being edited
          setEditingBranchData(branchDoc.data());
      }
  } catch (error) {
      console.error("Error fetching branch data for editing: ", error);
  }
};

const handleEditBranch = async (e, branchId) => {
  e.preventDefault();
  try {
      const branchRef = doc(db, "branches", branchId);
      await updateDoc(branchRef, editingBranchData);
      setEditingBranch(null); // Reset editing state
  } catch (error) {
      console.error("Error updating branch: ", error);
  }
  window.location.reload();
};

const handleExitEditingBranch = async ()=> {
    setEditingBranch(null);

};

const showAddUser = (branchId) => {
  setPopupState({
      ...popupState,
      selectedBranchId: branchId,
      showAddUserPopup: true,
      showAddAdminPopup: false,
      showAddDepartmentPopup: false,
      showRemoveUserPopup: false,
      showRemoveAdminPopup: false,
      showRemoveDepartmentPopup: false
  });
  setEditingBranch(null);
};

const handleAddUserToBranch = async (e, selectedBranchId) => {
    e.preventDefault();
  try {
      const branchRef = doc(db, "branches", popupState.selectedBranchId);
      const branchDoc = await getDoc(branchRef);

      if (branchDoc.exists()) {
          const currentUsers = branchDoc.data().branchUsers;
          const newUserId = popupState.newUserId;

          // Check if the new user ID is already in the array
          if (!currentUsers.includes(newUserId)) {
              const updatedUsers = [...currentUsers, newUserId];
              await updateDoc(branchRef, { branchUsers: updatedUsers });

              // Update local state
              const updatedBranches = branches.map(branch => {
                  if (branch.id === popupState.selectedBranchId) {
                      return { ...branch, branchUsers: updatedUsers };
                  }
                  return branch;
              });
              setBranches(updatedBranches);
          } else {
              alert(t.alertUserIDAlreadyExists);
              console.log("User ID already exists in the branchUsers array.");
          }
      }
  } catch (error) {
      console.error("Error adding user to branch: ", error);
  }
  setPopupState({ ...popupState, showAddUserPopup: false, newUserId: '' });
  window.location.reload();
};


const showAddAdmin = (branchId) => {
  setPopupState({
      ...popupState,
      selectedBranchId: branchId,
      showAddAdminPopup: true,
      showAddUserPopup: false,
      showAddDepartmentPopup: false,
      showRemoveUserPopup: false,
      showRemoveAdminPopup: false,
      showRemoveDepartmentPopup: false
  });
  setEditingBranch(null);
};
const handleAddAdminToBranch = async (e, selectedBranchId) => {
    e.preventDefault();
  try {
      const branchRef = doc(db, "branches", popupState.selectedBranchId);
      const branchDoc = await getDoc(branchRef);

      if (branchDoc.exists()) {
          const currentAdmins = branchDoc.data().branchAdmins;
          const newAdminId = popupState.newAdminId;

          // Check if the new admin ID is already in the array
          if (!currentAdmins.includes(newAdminId)) {
              const updatedAdmins = [...currentAdmins, newAdminId];
              await updateDoc(branchRef, { branchAdmins: updatedAdmins });

              // Update local state
              const updatedBranches = branches.map(branch => {
                  if (branch.id === popupState.selectedBranchId) {
                      return { ...branch, branchAdmins: updatedAdmins };
                  }
                  return branch;
              });
              setBranches(updatedBranches);
          } else {
              alert(t.alertAdminIDAlreadyExists);
              console.log("Admin ID already exists in the branchAdmins array.");
          }
      }
  } catch (error) {
      console.error("Error adding admin to branch: ", error);
  }
  setPopupState({ ...popupState, showAddAdminPopup: false, newAdminId: '' });
  window.location.reload();
};



const showAddDepartment = (branchId) => {
  setPopupState({
      ...popupState,
      selectedBranchId: branchId,
      showAddAdminPopup: false,
      showAddUserPopup: false,
      showAddDepartmentPopup: true,
      showRemoveUserPopup: false,
      showRemoveAdminPopup: false,
      showRemoveBranchPopup: false,
      showRemoveDepartmentPopup: false
  });
  setEditingBranch(null);

  setNewDepartmentData({
      departmentName: '',
      departmentEmail: '',
      street: '',
      houseNumber: '',
      postalCode: '',
      city: '',
      country: '',
      departmentPhoneNumber: ''
  });

};

const handleAddDepartmentToBranch = async (e, selectedBranchId) => {
    e.preventDefault();
  try {
      const departmentUID = `BRANCHDEP-${Date.now()}`;
      const newDepartment = {
          ...newDepartmentData,
          departmentUID,
          departmentBranch: popupState.selectedBranchId,
          creationTimestamp: serverTimestamp(),
          departmentAdmins: [auth.currentUser.uid],
          departmentUsers: [],
          departmentWarnings: [],
      };

      // Add new main department to the mainDepartments collection
      await setDoc(doc(db, "branchDepartments", departmentUID), newDepartment);

      // Update the branch's Departments array
      const branchRef = doc(db, "branches", popupState.selectedBranchId);
      const branchDoc = await getDoc(branchRef);
      if (branchDoc.exists()) {
          const updatedDepartments = [...branchDoc.data().branchDepartments, departmentUID];
          await updateDoc(branchRef, { branchDepartments: updatedDepartments });

          // Update local state
          const updatedBranches = branches.map(branch => {
              if (branch.id === popupState.selectedBranchId) {
                  return { ...branch, branchDepartments: updatedDepartments };
              }
              return branch;
          });
          setBranches(updatedBranches);
      }
  } catch (error) {
      console.error("Error adding main department to branch: ", error);
  }
  setPopupState({ ...popupState, showAddDepartmentPopup: false });
  window.location.reload();
};



const showRemoveUser = (branchId) => {
  setPopupState({
      ...popupState,
      selectedBranchId: branchId,
      showRemoveUserPopup: true,
      showRemoveAdminPopup: false,
      showRemoveBranchPopup: false,
      showRemoveDepartmentPopup: false,
      showAddAdminPopup: false,
      showAddUserPopup: false,
      showAddDepartmentPopup: false
  });
  setEditingBranch(null);
};

const handleRemoveUserFromBranch = async (e, selectedBranchId) => {
    e.preventDefault();
  try {
      const branchRef = doc(db, "branches", popupState.selectedBranchId);
      const branchDoc = await getDoc(branchRef);

      if (branchDoc.exists()) {
          const currentUsers = branchDoc.data().branchUsers;
          const userToRemove = popupState.userToRemoveId;

          // Check if the user to remove exists in the array
          if (currentUsers.includes(userToRemove)) {
              const updatedUsers = currentUsers.filter(userId => userId !== userToRemove);
              await updateDoc(branchRef, { branchUsers: updatedUsers });

              // Update local state
              const updatedBranches = branches.map(branch => {
                  if (branch.id === popupState.selectedBranchId) {
                      return { ...branch, branchUsers: updatedUsers };
                  }
                  return branch;
              });
              setBranches(updatedBranches);
          } else {
              alert(t.alertUserNotInBranch);
          }
      }
  } catch (error) {
      console.error("Error removing user from branch: ", error);
  }
  setPopupState({ ...popupState, showRemoveUserPopup: false, userToRemoveId: '' });
  window.location.reload();
};


const showRemoveAdmin = (branchId) => {
  setPopupState({
      ...popupState,
      selectedBranchId: branchId,
      showRemoveUserPopup: false,
      showRemoveAdminPopup: true,
      showRemoveBranchPopup: false,
      showRemoveDepartmentPopup: false,
      showAddAdminPopup: false,
      showAddUserPopup: false,
      showAddDepartmentPopup: false
  });
  setEditingBranch(null);
};


const handleRemoveAdminFromBranch = async (e, selectedBranchId) => {
    e.preventDefault();
  try {
      const branchRef = doc(db, "branches", popupState.selectedBranchId);
      const branchDoc = await getDoc(branchRef);

      if (branchDoc.exists()) {
          const currentAdmins = branchDoc.data().branchAdmins;
          const adminToRemove = popupState.adminToRemoveId;
          const currentUserUid = auth.currentUser.uid; // Get the current user's UID

          // Check if the admin to remove exists in the array
          if (currentAdmins.includes(adminToRemove)) {
              // Check if the admin to remove is the current user
              if (adminToRemove === currentUserUid) {
                  // Show confirmation dialog
                  if (window.confirm(t.leaveBranchAdminMessage)) {
                      // Proceed with removing the admin
                      await removeAdmin(currentAdmins, branchRef, adminToRemove);
                  }
              } else {
                  // Proceed with removing the admin
                  await removeAdmin(currentAdmins, branchRef, adminToRemove);
              }
          } else {
              alert(t.alertUserNotAdmin);
          }
      }
  } catch (error) {
      console.error("Error removing admin from branch: ", error);
  }
  setPopupState({ ...popupState, showRemoveAdminPopup: false, adminToRemoveId: '' });
  window.location.reload();
};

// Helper function to remove admin
const removeAdmin = async (currentAdmins, branchRef, adminToRemove) => {
  if (currentAdmins.length > 1) {
      const updatedAdmins = currentAdmins.filter(adminId => adminId !== adminToRemove);
      await updateDoc(branchRef, { branchAdmins: updatedAdmins });

      // Update local state
      const updatedBranches = branches.map(branch => {
          if (branch.id === popupState.selectedBranchId) {
              return { ...branch, branchAdmins: updatedAdmins };
          }
          return branch;
      });
      setBranches(updatedBranches);
  } else {
      alert(t.alertYouAreOnlyAdminInBranch);
  }
};




const showRemoveDepartment = (branchId) => {
  setPopupState({
      ...popupState,
      selectedBranchId: branchId,
      showRemoveUserPopup: false,
      showRemoveAdminPopup: false,
      showRemoveBranchPopup: false,
      showRemoveDepartmentPopup: true,
      showAddAdminPopup: false,
      showAddUserPopup: false,
      showAddDepartmentPopup: false
  });
  setEditingBranch(null);
};

const handleRemoveDepartmentFromBranch = async (e, selectedBranchId) => {
    e.preventDefault();
  if (!window.confirm(t.deleteDepartmentmessage)) {
      return;
  }

  try {
      const branchRef = doc(db, "branches", popupState.selectedBranchId);
      const branchDoc = await getDoc(branchRef);

      if (branchDoc.exists()) {
          const currentDepartments = branchDoc.data().branchDepartments;
          const departmentToRemove = popupState.departmentToRemoveId;

          // Check if the department to remove exists in the array
          if (currentDepartments.includes(departmentToRemove)) {
              const updatedDepartments = currentDepartments.filter(departmentId => departmentId !== departmentToRemove);
              await updateDoc(branchRef, { branchDepartments: updatedDepartments });

              // Move the department to the "deletedDepartments" collection
              const departmentRef = doc(db, "branchDepartments", departmentToRemove);
              const departmentDoc = await getDoc(departmentRef);
              if (departmentDoc.exists()) {
                  const departmentData = departmentDoc.data();
                  await setDoc(doc(db, "deletedbranchDepartments", departmentToRemove), {departmentData, deletedAt: new Date() });
                  await deleteDoc(departmentRef);
              }

              // Update local state
              const updatedBranches = branches.map(branch => {
                  if (branch.id === popupState.selectedBranchId) {
                      return { ...branch, branchDepartments: updatedDepartments };
                  }
                  return branch;
              });
              setBranches(updatedBranches);
          } else {
              alert(t.alertdepartmentNotInBranch);
          }
      }
  } catch (error) {
      console.error("Error removing department from branch: ", error);
  }
  setPopupState({ ...popupState, showRemoveDepartmentPopup: false, departmentToRemoveId: '' });
  window.location.reload();
};


const ArrayDisplay = ({ arrayData = [] }) => (
    <div>
      <ul>
        {Array.isArray(arrayData) && arrayData.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );

  const handleExitBranchPopups = async ()=> {
    setPopupState({
        ...popupState,
        showAddUserPopup: false,
        showAddAdminPopup: false,
        showAddDepartmentPopup: false,
        showRemoveUserPopup: false,
        showRemoveAdminPopup: false,
        showRemoveDepartmentPopup: false
    });

};


const renderList = (items) => {
    const commonHeaders = (
        <thead>
            <tr>
                <th>{t.branchUID}</th>
                <th>{t.branchName}</th>
                <th>{t.branchEmail}</th>
                <th>{t.street}</th>
                <th>{t.houseNumber}</th>
                <th>{t.postalCode}</th>
                <th>{t.city}</th>
                <th>{t.state}</th>
                <th>{t.country}</th>
                <th>{t.branchPhoneNumber}</th>
                <th>{t.editBranch}</th>
                <th>{t.branchUsers}</th>
                <th>{t.addUser}</th>
                <th>{t.removeUser}</th>
                <th>{t.branchAdmins}</th>
                <th>{t.addAdmin}</th>
                <th>{t.removeAdmin}</th>
                <th>{t.branchDepartments}</th>
                <th>{t.addDepartment}</th>
                <th>{t.removeDepartment}</th>
                <th>{t.leave}</th>
            </tr>
        </thead>
    );

    return items.length > 0 ? (
        <div className="table-main-container">
            <h2>{t.manageBranchesText}</h2>
            <table className="table-container">
                {commonHeaders}
                <tbody>
                    {items.map(item => (
                        <React.Fragment key={item.id}>
                            {item.branchAdmins && item.branchAdmins.includes(auth.currentUser.uid) ? (
                                <tr>
                                    <td>{item.branchUID}</td>
                                    <td>{item.branchName}</td>
                                    <td>{item.branchEmail}</td>
                                    <td>{item.street}</td>
                                    <td>{item.houseNumber}</td>
                                    <td>{item.postalCode}</td>
                                    <td>{item.city}</td>
                                    <td>{item.state}</td>
                                    <td>{item.country}</td>
                                    <td>{item.branchPhoneNumber}</td>
                                    <td><button className='button-edit' onClick={() => startEditBranch(item.id)}>{t.editBranch}</button></td>
                                    <td><ArrayDisplay arrayData={item.branchUsers || []} /></td>
                                    <td><button className='button-add' onClick={() => showAddUser(item.id)}>{t.addUser}</button></td>
                                    <td><button className='button-remove' onClick={() => showRemoveUser(item.id)}>{t.removeUser}</button></td>
                                    <td><ArrayDisplay arrayData={item.branchAdmins} /></td>
                                    <td><button className='button-add' onClick={() => showAddAdmin(item.id)}>{t.addAdmin}</button></td>
                                    <td><button className='button-remove' onClick={() => showRemoveAdmin(item.id)}>{t.removeAdmin}</button></td>
                                    <td><ArrayDisplay arrayData={item.branchDepartments} /></td>
                                    <td><button className='button-add' onClick={() => showAddDepartment(item.id)}>{t.addDepartment}</button></td>
                                    <td><button className='button-remove' onClick={() => showRemoveDepartment(item.id)}>{t.removeDepartment}</button></td>
                                    <td><button className='button-leave' onClick={() => handleLeaveAdminBranch(item.id)}>{t.leave}</button></td>
                                </tr>
                            ) : (
                                item.branchUsers && item.branchUsers.includes(auth.currentUser.uid) && (
                                    <tr>
                                        <td>{item.branchUID}</td>
                                        <td>{item.branchName}</td>
                                        <td>{item.branchEmail}</td>
                                        <td>{item.street}</td>
                                        <td>{item.houseNumber}</td>
                                        <td>{item.postalCode}</td>
                                        <td>{item.city}</td>
                                        <td>{item.state}</td>
                                        <td>{item.country}</td>
                                        <td>{item.branchPhoneNumber}</td>
                                        <td></td> {/* No edit button for regular users */}
                                        <td><ArrayDisplay arrayData={item.branchUsers || []} /></td>
                                        <td></td> {/* No add user button for regular users */}
                                        <td></td> {/* No remove user button for regular users */}
                                        <td><ArrayDisplay arrayData={item.branchAdmins} /></td>
                                        <td></td> {/* No add admin button for regular users */}
                                        <td></td> {/* No remove admin button for regular users */}
                                        <td><ArrayDisplay arrayData={item.branchDepartments} /></td>
                                        <td></td> {/* No add department button for regular users */}
                                        <td></td> {/* No remove department button for regular users */}
                                        <td><button className='button-leave' onClick={() => handleLeaveUserBranch(item.id)}>{t.leave}</button></td>
                                    </tr>
                                )
                            )}

                            {editingBranch === item.id && (
                                <tr className="popup-row">
                                    <td colSpan="21">
                                        <form onSubmit={(e) => handleEditBranch(e, item.id)} className="popup">
                                            <div><button type="button" onClick={handleExitEditingBranch} className="button-exit">X</button></div>
                                            <label htmlFor="branchName">{t.branchName}</label>
                                            <input type="text" name="branchName" id="branchName" value={editingBranchData.branchName} onChange={(e) => setEditingBranchData({ ...editingBranchData, branchName: e.target.value })} required />
                                            <label htmlFor="email">{t.branchEmail}</label>
                                            <input type="email" name="email" id="email" value={editingBranchData.branchEmail} onChange={(e) => setEditingBranchData({ ...editingBranchData, branchEmail: e.target.value })} required />
                                            <label htmlFor="street">{t.street}</label>
                                            <input type="text" name="street" id="street" value={editingBranchData.street} onChange={(e) => setEditingBranchData({ ...editingBranchData, street: e.target.value })} required />
                                            <label htmlFor="houseNumber">{t.houseNumber}</label>
                                            <input type="text" name="houseNumber" id="houseNumber" value={editingBranchData.houseNumber} onChange={(e) => setEditingBranchData({ ...editingBranchData, houseNumber: e.target.value })} required />
                                            <label htmlFor="postalCode">{t.postalCode}</label>
                                            <input type="text" name="postalCode" id="postalCode" value={editingBranchData.postalCode} onChange={(e) => setEditingBranchData({ ...editingBranchData, postalCode: e.target.value })} required />
                                            <label htmlFor="city">{t.city}</label>
                                            <input type="text" name="city" id="city" value={editingBranchData.city} onChange={(e) => setEditingBranchData({ ...editingBranchData, city: e.target.value })} required />
                                            <label htmlFor="state">{t.state}</label>
                                            <input type="text" name="state" id="state" value={editingBranchData.state} onChange={(e) => setEditingBranchData({ ...editingBranchData, state: e.target.value })} required />
                                            <label htmlFor="country">{t.country}</label>
                                            <input type="text" name="country" id="country" value={editingBranchData.country} onChange={(e) => setEditingBranchData({ ...editingBranchData, country: e.target.value })} required />
                                            <label htmlFor="branchPhoneNumber">{t.branchPhoneNumber}</label>
                                            <input type="tel" name="branchPhoneNumber" id="branchPhoneNumber" value={editingBranchData.branchPhoneNumber} onChange={(e) => setEditingBranchData({ ...editingBranchData, branchPhoneNumber: e.target.value })} required />
                                            <div>
                                                <button type="submit" className='button-submit'>{t.saveChanges}</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            )}

                            {popupState.showAddUserPopup && popupState.selectedBranchId === item.id && (
                                <tr className="popup-row">
                                    <td colSpan="21">
                                        <form onSubmit={(e) => handleAddUserToBranch(e, selectedBranchId)} className="popup">
                                            <div><button type="button" onClick={handleExitBranchPopups} className="button-exit">X</button></div>
                                            <input type="text" value={popupState.newUserId} onChange={(e) => setPopupState({ ...popupState, newUserId: e.target.value })} placeholder={t.enterUserID} required />
                                            <div>
                                                <button type="submit" className='button-add'>{t.addUser}</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            )}

                            {popupState.showRemoveUserPopup && popupState.selectedBranchId === item.id && (
                                <tr className="popup-row">
                                    <td colSpan="21">
                                        <form onSubmit={(e) => handleRemoveUserFromBranch(e, selectedBranchId)} className="popup">
                                            <div><button type="button" onClick={handleExitBranchPopups} className="button-exit">X</button></div>
                                            <input type="text" value={popupState.userToRemoveId} onChange={(e) => setPopupState({ ...popupState, userToRemoveId: e.target.value })} placeholder={t.enterUserID} required />
                                            <div>
                                                <button type="submit" className='button-remove'>{t.removeUser}</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            )}

                            {popupState.showAddAdminPopup && popupState.selectedBranchId === item.id && (
                                <tr className="popup-row">
                                    <td colSpan="21">
                                        <form onSubmit={(e) => handleAddAdminToBranch(e, selectedBranchId)} className="popup">
                                            <div><button type="button" onClick={handleExitBranchPopups} className="button-exit">X</button></div>
                                            <input type="text" value={popupState.newAdminId} onChange={(e) => setPopupState({ ...popupState, newAdminId: e.target.value })} placeholder={t.enterAdminID} required />
                                            <div>
                                                <button type="submit" className='button-add'>{t.addAdmin}</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            )}

                            {popupState.showRemoveAdminPopup && popupState.selectedBranchId === item.id && (
                                <tr className="popup-row">
                                    <td colSpan="21">
                                        <form onSubmit={(e) => handleRemoveAdminFromBranch(e, selectedBranchId)} className="popup">
                                            <div><button type="button" onClick={handleExitBranchPopups} className="button-exit">X</button></div>
                                            <input type="text" value={popupState.adminToRemoveId} onChange={(e) => setPopupState({ ...popupState, adminToRemoveId: e.target.value })} placeholder={t.enterAdminID} required />
                                            <div>
                                                <button type="submit" className='button-remove'>{t.removeAdmin}</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            )}

                            {popupState.showAddDepartmentPopup && popupState.selectedBranchId === item.id && (
                                <tr className="popup-row">
                                    <td colSpan="21">
                                        <form onSubmit={(e) => handleAddDepartmentToBranch(e, selectedBranchId)} className="popup">
                                            <div><button type="button" onClick={handleExitBranchPopups} className="button-exit">X</button></div>
                                            <label htmlFor="departmentName">{t.departmentName}</label>
                                            <input type="text" value={newDepartmentData.departmentName} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, departmentName: e.target.value })} placeholder={t.enterDepartmentName} required />
                                            <label htmlFor="email">{t.departmentEmail}</label>
                                            <input type="email" value={newDepartmentData.departmentEmail} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, departmentEmail: e.target.value })} placeholder={t.enterDepartmentEmail} required />
                                            <label htmlFor="street">{t.street}</label>
                                            <input type="text" value={newDepartmentData.street} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, street: e.target.value })} placeholder={t.enterStreet} required />
                                            <label htmlFor="houseNumber">{t.houseNumber}</label>
                                            <input type="text" value={newDepartmentData.houseNumber} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, houseNumber: e.target.value })} placeholder={t.enterHouseNumber} required />
                                            <label htmlFor="postalCode">{t.postalCode}</label>
                                            <input type="text" value={newDepartmentData.postalCode} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, postalCode: e.target.value })} placeholder={t.enterPostalCode} required />
                                            <label htmlFor="city">{t.city}</label>
                                            <input type="text" value={newDepartmentData.city} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, city: e.target.value })} placeholder={t.enterCity} required />
                                            <label htmlFor="state">{t.state}</label>
                                            <input type="text" value={newDepartmentData.state} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, state: e.target.value })} placeholder={t.enterState} required />
                                            <label htmlFor="country">{t.country}</label>
                                            <input type="text" value={newDepartmentData.country} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, country: e.target.value })} placeholder={t.enterCountry} required />
                                            <label htmlFor="departmentPhoneNumber">{t.departmentPhoneNumber}</label>
                                            <input type="tel" value={newDepartmentData.departmentPhoneNumber} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, departmentPhoneNumber: e.target.value })} placeholder={t.enterDepartmentPhoneNumber} required />
                                            <div>
                                                <button type="submit" className='button-add'>{t.addDepartment}</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            )}

                            {popupState.showRemoveDepartmentPopup && popupState.selectedBranchId === item.id && (
                                <tr className="popup-row">
                                    <td colSpan="21">
                                        <form onSubmit={(e) => handleRemoveDepartmentFromBranch(e, selectedBranchId)} className="popup">
                                            <div><button type="button" onClick={handleExitBranchPopups} className="button-exit">X</button></div>
                                            <input type="text" value={popupState.departmentToRemoveId} onChange={(e) => setPopupState({ ...popupState, departmentToRemoveId: e.target.value })} placeholder={t.enterDepartmentID} required />
                                            <div>
                                                <button type="submit" className='button-remove'>{t.removeDepartment}</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    ) : (
        <div className='table-main-container'>
            <h2>{t.manageBranchesText}</h2>
            <p>{t.emptyList}</p>
        </div>
    );
};

return (
    <div className="table-main-container">
        {renderList(branches)}
    </div>
);
};

export default ManageBranches;