import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import '../../../styles/global.css';

const Imprint = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].imprint;

  return (
    <div className="white-page-content">
    <div className="imprint">
    <h1>Impressum</h1>
    <h2>Angaben gemäß § 5 TMG</h2>
    <p>Trustec Valley Basali & Genedy GbR, Universitätsstraße 3, 56070 Koblenz</p>

    <h2>Kontakt</h2>
    <p>Telefon: +49 173 345 0556 , E-Mail: <a href="mailto:info@trustecvalley.com">info@trustecvalley.com</a></p>

    <p>Quelle: <a href="https://www.e-recht24.de" target="_blank">www.e-recht24.de</a></p>
    <p>Designquellen:</p>
    <p><a href="https://www.codingnepalweb.com/hoverable-sidebar-menu-html-css-javascript/" target="_blank">www.codingnepalweb.com</a></p>
    <p><a href="https://boxicons.com/?query=recy" target="_blank">www.boxicons.com</a></p>
    <p><a href="https://www.creative-tim.com/product/now-ui-dashboard-react#" target="_blank">www.creative-tim.com</a></p>
    
    </div>
    </div>
  );
};

export default Imprint;
