import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import db, { auth } from '../../backend/firebase/firebaseConfig';
import { collection, query, where, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import '../../styles/global.css';
const ManageDepartments = () => {

    const { language, translations } = useContext(LanguageContext);
    const t = translations[language].dashboard;

    
    const [departments, setDepartments] = useState([]);


  const [editingDepartment, setEditingDepartment] = useState(null);
  const [editingDepartmentData, setEditingDepartmentData] = useState({
      // Initialize with the same structure as newDepartmentData
  });

  const [selectedDepartmentId] = useState(null);

  const [popupState, setPopupState] = useState({
    selectedDepartmentId: null,
    
    showAddUserPopup: false,
    showAddAdminPopup: false,
    showRemoveUserPopup: false,
    showRemoveAdminPopup: false,
    newUserId: '',
    newAdminId: '',
    newDepartmentId: ''
});

useEffect(() => {
  const fetchDepartments = async () => {
      const user = auth.currentUser;
      if (user) {
          // Queries for branchDepartments
          const branchDepartmentsAdminQuery = query(collection(db, "branchDepartments"), where("departmentAdmins", "array-contains", user.uid));
          const branchDepartmentsAdminSnapshot = await getDocs(branchDepartmentsAdminQuery);
          const branchDepartmentsUserQuery = query(collection(db, "branchDepartments"), where("departmentUsers", "array-contains", user.uid));
          const branchDepartmentsUserSnapshot = await getDocs(branchDepartmentsUserQuery);

          // Queries for companyDepartments
          const companyDepartmentsAdminQuery = query(collection(db, "companyDepartments"), where("departmentAdmins", "array-contains", user.uid));
          const companyDepartmentsAdminSnapshot = await getDocs(companyDepartmentsAdminQuery);
          const companyDepartmentsUserQuery = query(collection(db, "companyDepartments"), where("departmentUsers", "array-contains", user.uid));
          const companyDepartmentsUserSnapshot = await getDocs(companyDepartmentsUserQuery);

          // Combine the results from all queries
          const combinedDepartments = [
              ...branchDepartmentsAdminSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
              ...branchDepartmentsUserSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
              ...companyDepartmentsAdminSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
              ...companyDepartmentsUserSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          ];

          // Remove duplicates
          const uniqueDepartments = Array.from(new Map(combinedDepartments.map(department => [department['id'], department])).values());

          setDepartments(uniqueDepartments);
      }
  };
  fetchDepartments();
}, []);



const handleLeaveUserDepartment = async (departmentId) => {
  if (window.confirm(t.leaveDepartmentUserMessage)) {
      try {
          const branchDepartmentRef = doc(db, "branchDepartments", departmentId);
          const companyDepartmentRef = doc(db, "companyDepartments", departmentId);
          const branchDepartmentDoc = await getDoc(branchDepartmentRef);
          const companyDepartmentDoc = await getDoc(companyDepartmentRef);

          if (branchDepartmentDoc.exists()) {
              const updatedUsers = branchDepartmentDoc.data().departmentUsers.filter(uid => uid !== auth.currentUser.uid);
              await updateDoc(branchDepartmentRef, { departmentUsers: updatedUsers });
              // Update local state or re-fetch departments
          }
          else if (companyDepartmentDoc.exists()){
            const updatedUsers = companyDepartmentDoc.data().departmentUsers.filter(uid => uid !== auth.currentUser.uid);
            await updateDoc(companyDepartmentRef, { departmentUsers: updatedUsers });
            // Update local state or re-fetch departments
          }
      } catch (error) {
          console.error("Error leaving department: ", error);
      }
  }
  window.location.reload();
};

const handleLeaveAdminDepartment = async (departmentId) => {
  if (window.confirm(t.leaveDepartmentAdminMessage)) {
      try {
        const branchDepartmentRef = doc(db, "branchDepartments", departmentId);
        const companyDepartmentRef = doc(db, "companyDepartments", departmentId);
        const branchDepartmentDoc = await getDoc(branchDepartmentRef);
        const companyDepartmentDoc = await getDoc(companyDepartmentRef);

          if (branchDepartmentDoc.exists()) {
              const currentAdmins = branchDepartmentDoc.data().departmentAdmins;
              const updatedAdmins = currentAdmins.filter(uid => uid !== auth.currentUser.uid);

              if (updatedAdmins.length === 0) {
                  // If the current user is the only admin
                  alert(t.alertYouAreOnlyAdminInDepartment);
              } else {
                  // If there are other admins, proceed with leaving
                  await updateDoc(branchDepartmentRef, { departmentAdmins: updatedAdmins });
                  // Update local state or re-fetch departments
              }
          }
          else if (companyDepartmentDoc.exists()){
            const currentAdmins = companyDepartmentDoc.data().departmentAdmins;
            const updatedAdmins = currentAdmins.filter(uid => uid !== auth.currentUser.uid);

            if (updatedAdmins.length === 0) {
                // If the current user is the only admin
                alert(t.alertYouAreOnlyAdminInDepartment);
            } else {
                // If there are other admins, proceed with leaving
                await updateDoc(companyDepartmentRef, { departmentAdmins: updatedAdmins });
                // Update local state or re-fetch departments
            }
          }
      } catch (error) {
          console.error("Error leaving department: ", error);
      }
  }
  window.location.reload();
};


const startEditDepartment = async (departmentId) => {
try {
  const branchDepartmentRef = doc(db, "branchDepartments", departmentId);
  const companyDepartmentRef = doc(db, "companyDepartments", departmentId);
  const branchDepartmentDoc = await getDoc(branchDepartmentRef);
  const companyDepartmentDoc = await getDoc(companyDepartmentRef);

  setPopupState({
    ...popupState,
    showAddUserPopup: false,
    showAddAdminPopup: false,
    showRemoveUserPopup: false,
    showRemoveAdminPopup: false
});

    if (branchDepartmentDoc.exists()) {
        setEditingDepartment(departmentId); // Set the ID of the department being edited
        setEditingDepartmentData(branchDepartmentDoc.data());
    }
    else if (companyDepartmentDoc.exists()){
      setEditingDepartment(departmentId); // Set the ID of the department being edited
      setEditingDepartmentData(companyDepartmentDoc.data());
    }
} catch (error) {
    console.error("Error fetching department data for editing: ", error);
}
};

const handleEditDepartment = async (e, departmentId) => {
e.preventDefault();
try {
  const branchDepartmentRef = doc(db, "branchDepartments", departmentId);
  const companyDepartmentRef = doc(db, "companyDepartments", departmentId);
  const branchDepartmentDoc = await getDoc(branchDepartmentRef);
  const companyDepartmentDoc = await getDoc(companyDepartmentRef);
  

  if (branchDepartmentDoc.exists()) {
    await updateDoc(branchDepartmentRef, editingDepartmentData);
    setEditingDepartment(null); // Reset editing state
  }
  else if (companyDepartmentDoc.exists()){
    await updateDoc(companyDepartmentRef, editingDepartmentData);
    setEditingDepartment(null); // Reset editing state
  }
} catch (error) {
    console.error("Error updating department: ", error);
}
window.location.reload();
};


const handleExitEditingDepartment = async ()=> {
    setEditingDepartment(null);

};


const showAddUser = (departmentId) => {
setPopupState({
    ...popupState,
    selectedDepartmentId: departmentId,
    
    showAddUserPopup: true,
    showAddAdminPopup: false,
    showRemoveUserPopup: false,
    showRemoveAdminPopup: false
});
setEditingDepartment(null);
};

const handleAddUserToDepartment = async (e, departmentId) => {
    e.preventDefault();
try {
  const branchDepartmentRef = doc(db, "branchDepartments", popupState.selectedDepartmentId);
  const companyDepartmentRef = doc(db, "companyDepartments", popupState.selectedDepartmentId);
  const branchDepartmentDoc = await getDoc(branchDepartmentRef);
  const companyDepartmentDoc = await getDoc(companyDepartmentRef);

    if (branchDepartmentDoc.exists()) {
        const currentUsers = branchDepartmentDoc.data().departmentUsers;
        const newUserId = popupState.newUserId;

        // Check if the new user ID is already in the array
        if (!currentUsers.includes(newUserId)) {
            const updatedUsers = [...currentUsers, newUserId];
            await updateDoc(branchDepartmentRef, { departmentUsers: updatedUsers });

            // Update local state
            const updatedDepartments = departments.map(department => {
                if (department.id === popupState.selectedDepartmentId) {
                    return { ...department, departmentUsers: updatedUsers };
                }
                return department;
            });
            setDepartments(updatedDepartments);
        } else {
            alert(t.alertUserIDAlreadyExists);
            console.log("User ID already exists in the departmentUsers array.");
        }
    }
    else if (companyDepartmentDoc.exists()) {

      const currentUsers = companyDepartmentDoc.data().departmentUsers;
      const newUserId = popupState.newUserId;

      // Check if the new user ID is already in the array
      if (!currentUsers.includes(newUserId)) {
          const updatedUsers = [...currentUsers, newUserId];
          await updateDoc(companyDepartmentRef, { departmentUsers: updatedUsers });

          // Update local state
          const updatedDepartments = departments.map(department => {
              if (department.id === popupState.selectedDepartmentId) {
                  return { ...department, departmentUsers: updatedUsers };
              }
              return department;
          });
          setDepartments(updatedDepartments);
      } else {
          alert(t.alertUserIDAlreadyExists);
          console.log("User ID already exists in the departmentUsers array.");
      }

    }
} catch (error) {
    console.error("Error adding user to department: ", error);
}
setPopupState({ ...popupState, showAddUserPopup: false, newUserId: '' });
window.location.reload();
};


const showAddAdmin = (departmentId) => {
setPopupState({
    ...popupState,
    selectedDepartmentId: departmentId,
    showAddAdminPopup: true,
    showAddUserPopup: false,
    showRemoveUserPopup: false,
    showRemoveAdminPopup: false
});
setEditingDepartment(null);
};
const handleAddAdminToDepartment = async (e, departmentId) => {
    e.preventDefault();
try {
  const branchDepartmentRef = doc(db, "branchDepartments", popupState.selectedDepartmentId);
  const companyDepartmentRef = doc(db, "companyDepartments", popupState.selectedDepartmentId);
  const branchDepartmentDoc = await getDoc(branchDepartmentRef);
  const companyDepartmentDoc = await getDoc(companyDepartmentRef);

    if (branchDepartmentDoc.exists()) {
        const currentAdmins = branchDepartmentDoc.data().departmentAdmins;
        const newAdminId = popupState.newAdminId;

        // Check if the new admin ID is already in the array
        if (!currentAdmins.includes(newAdminId)) {
            const updatedAdmins = [...currentAdmins, newAdminId];
            await updateDoc(branchDepartmentRef, { departmentAdmins: updatedAdmins });

            // Update local state
            const updatedDepartments = departments.map(department => {
                if (department.id === popupState.selectedDepartmentId) {
                    return { ...department, departmentAdmins: updatedAdmins };
                }
                return department;
            });
            setDepartments(updatedDepartments);
        } else {
            alert(t.alertAdminIDAlreadyExists);
            console.log("Admin ID already exists in the departmentAdmins array.");
        }
    }
    else if (companyDepartmentDoc.exists()) {
      const currentAdmins = companyDepartmentDoc.data().departmentAdmins;
      const newAdminId = popupState.newAdminId;

      // Check if the new admin ID is already in the array
      if (!currentAdmins.includes(newAdminId)) {
          const updatedAdmins = [...currentAdmins, newAdminId];
          await updateDoc(companyDepartmentRef, { departmentAdmins: updatedAdmins });

          // Update local state
          const updatedDepartments = departments.map(department => {
              if (department.id === popupState.selectedDepartmentId) {
                  return { ...department, departmentAdmins: updatedAdmins };
              }
              return department;
          });
          setDepartments(updatedDepartments);
      } else {
          alert(t.alertAdminIDAlreadyExists);
          console.log("Admin ID already exists in the departmentAdmins array.");
      }
  }
} catch (error) {
    console.error("Error adding admin to department: ", error);
}
setPopupState({ ...popupState, showAddAdminPopup: false, newAdminId: '' });
window.location.reload();
};



const showRemoveUser = (departmentId) => {
  setPopupState({
      ...popupState,
      selectedDepartmentId: departmentId,
      
      showRemoveUserPopup: true,
      showRemoveAdminPopup: false,
      showAddAdminPopup: false,
      showAddUserPopup: false,
  });
  setEditingDepartment(null);
};

const handleRemoveUserFromDepartment = async (e, departmentId) => {
    e.preventDefault();
  try {
    const branchDepartmentRef = doc(db, "branchDepartments", popupState.selectedDepartmentId);
    const companyDepartmentRef = doc(db, "companyDepartments", popupState.selectedDepartmentId);
    const branchDepartmentDoc = await getDoc(branchDepartmentRef);
    const companyDepartmentDoc = await getDoc(companyDepartmentRef);

      if (branchDepartmentDoc.exists()) {
          const currentUsers = branchDepartmentDoc.data().departmentUsers;
          const userToRemove = popupState.userToRemoveId;

          // Check if the user to remove exists in the array
          if (currentUsers.includes(userToRemove)) {
              const updatedUsers = currentUsers.filter(userId => userId !== userToRemove);
              await updateDoc(branchDepartmentRef, { departmentUsers: updatedUsers });

              // Update local state
              const updatedDepartments = departments.map(department => {
                  if (department.id === popupState.selectedDepartmentId) {
                      return { ...department, departmentUsers: updatedUsers };
                  }
                  return department;
              });
              setDepartments(updatedDepartments);
          } else {
              alert(t.alertUserNotInDepartment);
          }
      }
      else if (companyDepartmentDoc.exists()) {
        const currentUsers = companyDepartmentDoc.data().departmentUsers;
        const userToRemove = popupState.userToRemoveId;

        // Check if the user to remove exists in the array
        if (currentUsers.includes(userToRemove)) {
            const updatedUsers = currentUsers.filter(userId => userId !== userToRemove);
            await updateDoc(companyDepartmentRef, { departmentUsers: updatedUsers });

            // Update local state
            const updatedDepartments = departments.map(department => {
                if (department.id === popupState.selectedDepartmentId) {
                    return { ...department, departmentUsers: updatedUsers };
                }
                return department;
            });
            setDepartments(updatedDepartments);
        } else {
            alert(t.alertUserNotInDepartment);
        }
    }
  } catch (error) {
      console.error("Error removing user from department: ", error);
  }
  setPopupState({ ...popupState, showRemoveUserPopup: false, userToRemoveId: '' });
  window.location.reload();
};


const showRemoveAdmin = (departmentId) => {
  setPopupState({
      ...popupState,
      selectedDepartmentId: departmentId,
      
      showRemoveUserPopup: false,
      showRemoveAdminPopup: true,
      showAddAdminPopup: false,
      showAddUserPopup: false,
  });
  setEditingDepartment(null);
};


const handleRemoveAdminFromDepartment = async (e, departmentId) => {
    e.preventDefault();
  try {
    const branchDepartmentRef = doc(db, "branchDepartments", popupState.selectedDepartmentId);
    const companyDepartmentRef = doc(db, "companyDepartments", popupState.selectedDepartmentId);
    const branchDepartmentDoc = await getDoc(branchDepartmentRef);
    const companyDepartmentDoc = await getDoc(companyDepartmentRef);

      if (branchDepartmentDoc.exists()) {
          const currentAdmins = branchDepartmentDoc.data().departmentAdmins;
          const adminToRemove = popupState.adminToRemoveId;
          const currentUserUid = auth.currentUser.uid; // Get the current user's UID

          // Check if the admin to remove exists in the array
          if (currentAdmins.includes(adminToRemove)) {
              // Check if the admin to remove is the current user
              if (adminToRemove === currentUserUid) {
                  // Show confirmation dialog
                  if (window.confirm(t.leaveDepartmentAdminMessage)) {
                      // Proceed with removing the admin
                      await removeAdmin(currentAdmins, branchDepartmentRef, adminToRemove);
                  }
              } else {
                  // Proceed with removing the admin
                  await removeAdmin(currentAdmins, branchDepartmentRef, adminToRemove);
              }
          } else {
              alert(t.alertUserNotAdmin);
          }
      }
      else if (companyDepartmentDoc.exists()) {
        const currentAdmins = companyDepartmentDoc.data().departmentAdmins;
        const adminToRemove = popupState.adminToRemoveId;
        const currentUserUid = auth.currentUser.uid; // Get the current user's UID

        // Check if the admin to remove exists in the array
        if (currentAdmins.includes(adminToRemove)) {
            // Check if the admin to remove is the current user
            if (adminToRemove === currentUserUid) {
                // Show confirmation dialog
                if (window.confirm(t.leaveDepartmentAdminMessage)) {
                    // Proceed with removing the admin
                    await removeAdmin(currentAdmins, companyDepartmentRef, adminToRemove);
                }
            } else {
                // Proceed with removing the admin
                await removeAdmin(currentAdmins, companyDepartmentRef, adminToRemove);
            }
        } else {
            alert(t.alertUserNotAdmin);
        }
    }
  } catch (error) {
      console.error("Error removing admin from department: ", error);
  }
  setPopupState({ ...popupState, showRemoveAdminPopup: false, adminToRemoveId: '' });
  window.location.reload();
};

// Helper function to remove admin
const removeAdmin = async (currentAdmins, departmentRef, adminToRemove) => {
  if (currentAdmins.length > 1) {
      const updatedAdmins = currentAdmins.filter(adminId => adminId !== adminToRemove);
      await updateDoc(departmentRef, { departmentAdmins: updatedAdmins });

      // Update local state
      const updatedDepartments = departments.map(department => {
          if (department.id === popupState.selectedDepartmentId) {
              return { ...department, departmentAdmins: updatedAdmins };
          }
          return department;
      });
      setDepartments(updatedDepartments);
  } else {
      alert(t.alertYouAreOnlyAdminInDepartment);
  }
};


const ArrayDisplay = ({ arrayData = [] }) => (
  <div>
    <ul>
      {Array.isArray(arrayData) && arrayData.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </div>
);

const handleExitDepartmentPopups = async ()=> {
    setPopupState({
        ...popupState,
        
        showRemoveUserPopup: false,
        showRemoveAdminPopup: false,
        showAddAdminPopup: false,
        showAddUserPopup: false,
    });

};




const renderList = (items) => {
    const commonHeaders = (
        <thead>
            <tr>
                <th>{t.departmentUID}</th>
                <th>{t.ownerCompanyOrBranch}</th>
                <th>{t.departmentName}</th>
                <th>{t.departmentEmail}</th>
                <th>{t.street}</th>
                <th>{t.houseNumber}</th>
                <th>{t.postalCode}</th>
                <th>{t.city}</th>
                <th>{t.state}</th>
                <th>{t.country}</th>
                <th>{t.departmentPhoneNumber}</th>
                <th>{t.editDepartment}</th>
                <th>{t.departmentUsers}</th>
                <th>{t.addUser}</th>
                <th>{t.removeUser}</th>
                <th>{t.departmentAdmins}</th>
                <th>{t.addAdmin}</th>
                <th>{t.removeAdmin}</th>
                <th>{t.leave}</th>
            </tr>
        </thead>
    );

    return items.length > 0 ? (
        <div className="table-main-container">
            <h2>{t.manageDepartmentsText}</h2>
            <table className="table-container">
                {commonHeaders}
                <tbody>
                    {items.map(item => (
                        <React.Fragment key={item.id}>
                            {item.departmentAdmins && item.departmentAdmins.includes(auth.currentUser.uid) ? (
                                <tr>
                                    <td>{item.departmentUID}</td>
                                    <td>{item.departmentCompany || ''} {item.departmentBranch || ''}</td>
                                    <td>{item.departmentName}</td>
                                    <td>{item.departmentEmail}</td>
                                    <td>{item.street}</td>
                                    <td>{item.houseNumber}</td>
                                    <td>{item.postalCode}</td>
                                    <td>{item.city}</td>
                                    <td>{item.state}</td>
                                    <td>{item.country}</td>
                                    <td>{item.departmentPhoneNumber}</td>
                                    <td><button className='button-edit' onClick={() => startEditDepartment(item.id)}>{t.editDepartment}</button></td>
                                    <td><ArrayDisplay arrayData={item.departmentUsers || []} /></td>
                                    <td><button className='button-add' onClick={() => showAddUser(item.id)}>{t.addUser}</button></td>
                                    <td><button className='button-remove' onClick={() => showRemoveUser(item.id)}>{t.removeUser}</button></td>
                                    <td><ArrayDisplay arrayData={item.departmentAdmins || []} /></td>
                                    <td><button className='button-add' onClick={() => showAddAdmin(item.id)}>{t.addAdmin}</button></td>
                                    <td><button className='button-remove' onClick={() => showRemoveAdmin(item.id)}>{t.removeAdmin}</button></td>
                                    <td><button className='button-leave' onClick={() => handleLeaveAdminDepartment(item.id)}>{t.leave}</button></td>
                                </tr>
                            ) : (
                                item.departmentUsers && item.departmentUsers.includes(auth.currentUser.uid) && (
                                    <tr>
                                        <td>{item.departmentUID}</td>
                                        <td>{item.departmentCompany || ''} {item.departmentBranch || ''}</td>
                                        <td>{item.departmentName}</td>
                                        <td>{item.departmentEmail}</td>
                                        <td>{item.street}</td>
                                        <td>{item.houseNumber}</td>
                                        <td>{item.postalCode}</td>
                                        <td>{item.city}</td>
                                        <td>{item.state}</td>
                                        <td>{item.country}</td>
                                        <td>{item.departmentPhoneNumber}</td>
                                        <td></td> {/* No edit button for regular users */}
                                        <td><ArrayDisplay arrayData={item.departmentUsers || []} /></td>
                                        <td></td> {/* No add user button for regular users */}
                                        <td></td> {/* No remove user button for regular users */}
                                        <td><ArrayDisplay arrayData={item.departmentAdmins || []} /></td>
                                        <td></td> {/* No add admin button for regular users */}
                                        <td></td> {/* No remove admin button for regular users */}
                                        <td><button className='button-leave' onClick={() => handleLeaveUserDepartment(item.id)}>{t.leave}</button></td>
                                    </tr>
                                )
                            )}

                            {editingDepartment === item.id && (
                                <tr className="popup-row">
                                    <td colSpan="19">
                                        <form onSubmit={(e) => handleEditDepartment(e, item.id)} className="popup">
                                            <div><button type="button" onClick={handleExitEditingDepartment} className="button-exit">X</button></div>
                                            <label htmlFor="departmentName">{t.departmentName}</label>
                                            <input type="text" name="departmentName" id="departmentName" value={editingDepartmentData.departmentName} onChange={(e) => setEditingDepartmentData({ ...editingDepartmentData, departmentName: e.target.value })} required />
                                            <label htmlFor="email">{t.departmentEmail}</label>
                                            <input type="email" name="email" id="email" value={editingDepartmentData.departmentEmail} onChange={(e) => setEditingDepartmentData({ ...editingDepartmentData, departmentEmail: e.target.value })} required />
                                            <label htmlFor="street">{t.street}</label>
                                            <input type="text" name="street" id="street" value={editingDepartmentData.street} onChange={(e) => setEditingDepartmentData({ ...editingDepartmentData, street: e.target.value })} required />
                                            <label htmlFor="houseNumber">{t.houseNumber}</label>
                                            <input type="text" name="houseNumber" id="houseNumber" value={editingDepartmentData.houseNumber} onChange={(e) => setEditingDepartmentData({ ...editingDepartmentData, houseNumber: e.target.value })} required />
                                            <label htmlFor="postalCode">{t.postalCode}</label>
                                            <input type="text" name="postalCode" id="postalCode" value={editingDepartmentData.postalCode} onChange={(e) => setEditingDepartmentData({ ...editingDepartmentData, postalCode: e.target.value })} required />
                                            <label htmlFor="city">{t.city}</label>
                                            <input type="text" name="city" id="city" value={editingDepartmentData.city} onChange={(e) => setEditingDepartmentData({ ...editingDepartmentData, city: e.target.value })} required />
                                            <label htmlFor="state">{t.state}</label>
                                            <input type="text" name="state" id="state" value={editingDepartmentData.state} onChange={(e) => setEditingDepartmentData({ ...editingDepartmentData, state: e.target.value })} required />
                                            <label htmlFor="country">{t.country}</label>
                                            <input type="text" name="country" id="country" value={editingDepartmentData.country} onChange={(e) => setEditingDepartmentData({ ...editingDepartmentData, country: e.target.value })} required />
                                            <label htmlFor="departmentPhoneNumber">{t.departmentPhoneNumber}</label>
                                            <input type="tel" name="departmentPhoneNumber" id="departmentPhoneNumber" value={editingDepartmentData.departmentPhoneNumber} onChange={(e) => setEditingDepartmentData({ ...editingDepartmentData, departmentPhoneNumber: e.target.value })} required />
                                            <div>
                                                <button type="submit" className='button-submit'>{t.saveChanges}</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            )}

                            {popupState.showAddUserPopup && popupState.selectedDepartmentId === item.id && (
                                <tr className="popup-row">
                                    <td colSpan="19">
                                        <form onSubmit={(e) => handleAddUserToDepartment(e, selectedDepartmentId)} className="popup">
                                            <div><button type="button" onClick={handleExitDepartmentPopups} className="button-exit">X</button></div>
                                            <input type="text" value={popupState.newUserId} onChange={(e) => setPopupState({ ...popupState, newUserId: e.target.value })} placeholder={t.enterUserID} required />
                                            <div>
                                                <button type="submit" className='button-add'>{t.addUser}</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            )}

                            {popupState.showRemoveUserPopup && popupState.selectedDepartmentId === item.id && (
                                <tr className="popup-row">
                                    <td colSpan="19">
                                        <form onSubmit={(e) => handleRemoveUserFromDepartment(e, selectedDepartmentId)} className="popup">
                                            <div><button type="button" onClick={handleExitDepartmentPopups} className="button-exit">X</button></div>
                                            <input type="text" value={popupState.userToRemoveId} onChange={(e) => setPopupState({ ...popupState, userToRemoveId: e.target.value })} placeholder={t.enterUserID} required />
                                            <div>
                                                <button type="submit" className='button-remove'>{t.removeUser}</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            )}

                            {popupState.showAddAdminPopup && popupState.selectedDepartmentId === item.id && (
                                <tr className="popup-row">
                                    <td colSpan="19">
                                        <form onSubmit={(e) => handleAddAdminToDepartment(e, selectedDepartmentId)} className="popup">
                                            <div><button type="button" onClick={handleExitDepartmentPopups} className="button-exit">X</button></div>
                                            <input type="text" value={popupState.newAdminId} onChange={(e) => setPopupState({ ...popupState, newAdminId: e.target.value })} placeholder={t.enterAdminID} required />
                                            <div>
                                                <button type="submit" className='button-add'>{t.addAdmin}</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            )}

                            {popupState.showRemoveAdminPopup && popupState.selectedDepartmentId === item.id && (
                                <tr className="popup-row">
                                    <td colSpan="19">
                                        <form onSubmit={(e) => handleRemoveAdminFromDepartment(e, selectedDepartmentId)} className="popup">
                                            <div><button type="button" onClick={handleExitDepartmentPopups} className="button-exit">X</button></div>
                                            <input type="text" value={popupState.adminToRemoveId} onChange={(e) => setPopupState({ ...popupState, adminToRemoveId: e.target.value })} placeholder={t.enterAdminID} required />
                                            <div>
                                                <button type="submit" className='button-remove'>{t.removeAdmin}</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    ) : (
        <div className='table-main-container'>
            <h2>{t.manageDepartmentsText}</h2>
            <p>{t.emptyList}</p>
        </div>
    );
};

return <div className="table-main-container">{renderList(departments)}</div>;
};

export default ManageDepartments;