import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import '../../../styles/global.css';

const DataProtection = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].dataProtection;

  return (
  <div className="white-page-content">
  <div className="dataProtection">

<h1>Datenschutzhinweis für den LogiHash Login</h1>

<h2>§1 Allgemeines</h2>
<p>Der Schutz Ihrer persönlichen Daten hat für den Webseitenbetreiber einen hohen Stellenwert. Es ist uns wichtig, Sie darüber zu informieren, welche persönlichen Daten erfasst werden, wie diese verwendet werden und welche Gestaltungsmöglichkeiten Sie dabei haben.</p>

<h2>§2 Welche Daten werden erfasst, wie werden sie verwendet und wie lange werden sie gespeichert?</h2>
<p>Bei der Nutzung der Webseite sowie Application, im folgenden Online-Dienst genannt: Für die Erbringung unserer Leistungen zur Authentifizierung/ Autorisierung mittels LogiHash Login wird ein Benutzername in Form einer frei wählbaren E-MailAdresse oder einer Mobilfunk-Nummer, ein Passwort sowie zur Wiederherstellung des Accounts die Mobilfunknummer, eine Antwort auf persönliche Sicherheitsfrage und Geburtsdatum gespeichert. Zudem wird die Adresse, das Geburtsdatum und ggf. das Profilbild gespeichert. Der Benutzername darf keine Rechte von Dritten beeinträchtigen, im Fall eines Verstoßes behält sich der Webseitenbetreiber vor den Account zu deaktivieren. Beim Login-Versuch wird ein pseudonymes Nutzerprofil angelegt, um Informationen zur Nutzung des Logins zu erfassen, wie Mausbewegungen, Tastaturanschläge, Geräteinformationen (z.B. Browserinformation, Betriebssystem, Bildschirmauflösung, pseudonymisierte IP-Adresse, etc.). Diese werden in einer Datenbank zwecks Risiko-Ermittlung zur Erkennung von missbräuchlicher Nutzung der Identität und verdächtigen Login-Versuchen für 6 Monate gespeichert. Wenn Sie unseren Online-Dienst nutzen, verzeichnen unsere Server vorübergehend den Domain-Namen oder die IP-Adresse Ihres Endgerätes sowie weitere Daten, wie z. B. die angefragten Inhalte oder den AntwortCode (Art. 6 Abs. 1b DSGVO, §25 Abs. 2 Nr. 2 TTDSG). Die protokollierten Daten werden ausschließlich für Zwecke der Datensicherheit, insbesondere zur Abwehr von Angriffsversuchen auf unseren Webserver verwendet (Art. 6 Abs. 1f DSGVO). Sie werden weder für die Erstellung von individuellen Anwenderprofilen verwendet noch an Dritte weitergegeben und werden nach spätestens 7 Tagen gelöscht. Die statistische Auswertung anonymisierter Datensätze behalten wir uns vor.</p>

<p>Wir können Ihre personenbezogenen Daten (sowie auch die personenbezogenen Daten der Nutzer von Nutzern) so lange speichern, wie Ihr Nutzerkonto aktiv ist, wie in dieser Datenschutzrichtlinie angegeben oder wie dies anderweitig erforderlich ist, um Ihnen unsere Dienste zur Verfügung zu stellen. Wir können solche personenbezogenen Daten auch über die Deaktivierung Ihres Nutzerkontos und/oder nach Einstellung Ihrer Nutzung eines bestimmten Dienstes hinaus speichern, insoweit dies zur Einhaltung unserer gesetzlichen Verpflichtungen erforderlich ist, um Streitigkeiten bezüglich unserer Nutzer oder der Nutzer von Nutzern beizulegen, Betrug und Missbrauch zu verhindern, unsere Verträge durchzusetzen und/oder unsere berechtigten Interessen zu schützen. Bei der Festlegung der angemessenen Aufbewahrungsfrist für personenbezogene Daten berücksichtigen wir die Menge, die Art und die Sensibilität dieser Daten, das potenzielle Risiko eines Schadens durch unbefugte Nutzung oder Offenlegung dieser Daten, die Zwecke, für die wir sie verarbeiten, und die geltenden gesetzlichen Bestimmungen.</p>

<h2>§3 Legitimationsprüfung</h2>
<p>Für die Legitimationsprüfung im Rahmen einer Registrierung nutzen wir innerhalb des Videochats den Service Video Ident des Auftragsverarbeiters Purpleview GmbH, Kampstraße 6, 44137 Dortmund (Art. 6 Abs. 1b DSGVO). Kommunikationsdaten, die innerhalb des Gesprächs oder Chat ausgetauscht werden, werden nicht gespeichert, dazu gehört auch der angegebene Name.</p>

<h2>§4 Benutzername merken</h2>
<p>Ihr Browser kann sich Ihren Benutzernamen bei der Anmeldung mit dem LogiHash Login merken, wenn Sie das Kontrollkästchen aktivieren. Dadurch müssen Sie bei der nächsten Anmeldung Ihren Benutzernamen nicht erneut eingeben. Wenn Sie den LogiHash Login länger als 180 Tage nicht benutzt haben, werden Sie dennoch gebeten Ihren Benutzernamen erneut einzugeben. Sie können sich jederzeit mit einem anderen Benutzernamen beim LogiHash Login anmelden. Dazu klicken Sie einfach auf den Link "Nicht Ihr Benutzername?"</p>

<h2>§5 Angemeldet bleiben</h2>
<p>Wenn Sie sich mit Ihrem Benutzernamen (E-Mail-Adresse oder MobilfunkNummer) und Ihrem Passwort anmelden, kann sich der Browser die Anmeldung merken, sofern Sie das Kontrollkästchen aktivieren. Sie müssen sich dann nicht jedes Mal neu mit Ihrem LogiHash Login anmelden, wenn Sie einen LogiHash Dienst nutzen wollen. Wenn Sie den Login länger als 180 Tage nicht benutzt haben, werden Sie dennoch gebeten sich wieder mit Ihrem Passwort anzumelden. Sie können sich jederzeit beim LogiHash Login abmelden, indem Sie auf den "Logout"-Button klicken. Dieser befindet sich je nach Dienst in der Navigationsleiste. Sobald Sie abgemeldet sind, können Sie sich neu anmelden und das Kontrollkästchen wieder aktivieren oder deaktivieren.</p>

<h2>§6 Wer ist verantwortlich für die Datenverarbeitung? Wer ist mein Ansprechpartner, wenn ich Fragen zum Datenschutz bei der Telekom habe?</h2>
<p>Datenverantwortliche ist Trustec Valley Basali & Genedy GbR, Universitätsstraße 3, 56070 Koblenz. E-Mail-Adresse: info@trustecvalley.com</p>

<h2>§7 Welche Rechte habe ich?</h2>
<p>Sie haben das Recht, a) Auskunft zu verlangen zu Kategorien der verarbeiteten Daten, Verarbeitungszwecken, etwaigen Empfängern der Daten, der geplanten Speicherdauer (Art. 15 DSGVO); b) die Berichtigung bzw. Ergänzung unrichtiger bzw. unvollständiger Daten zu verlangen (Art. 16 DSGVO); c) eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen (Art. 7 Abs. 3 DSGVO); d) einer Datenverarbeitung, die aufgrund eines berechtigten Interesses erfolgen soll, aus Gründen zu widersprechen, die sich aus Ihrer besonderen Situation ergeben (Art 21 Abs. 1 DSGVO); e) in bestimmten Fällen im Rahmen des Art. 17 DSGVO die Löschung von Daten zu verlangen - insbesondere soweit die Daten für den vorgesehenen Zweck nicht mehr erforderlich sind bzw. unrechtmäßig verarbeitet werden, oder Sie Ihre Einwilligung gemäß oben (c) widerrufen oder einen Widerspruch gemäß oben (d) erklärt haben; f) unter bestimmten Voraussetzungen die Einschränkung von Daten zu verlangen, soweit eine Löschung nicht möglich bzw. die Löschpflicht streitig ist (Art. 18 DSGVO); g) auf Datenübertragbarkeit, d.h. Sie können Ihre Daten, die Sie uns bereitgestellt haben, in einem gängigen maschinenlesbaren Format wie z.B. CSV erhalten und ggf. an andere übermitteln (Art. 20 DSGVO;) h) sich bei der zuständigen Aufsichtsbehörde über die Datenverarbeitung zu beschweren (Landesbeauftragte für den Datenschutz und die Informationsfreiheit Saarland).</p>

<h2>§8 Hosting</h2>
    <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter: Externes Hosting. Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln. Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar. Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen. Wir setzen folgende(n) Hoster ein: Firebase.</p>

    <h2>§9 An wen gibt der Webseitenbetreiber meine Daten weiter?</h2>
    <p>An Auftragsverarbeiter, das sind Unternehmen, die wir im gesetzlich vorgesehenen Rahmen mit der Verarbeitung von Daten beauftragen, Art. 28 DSGVO (Dienstleister, Erfüllungsgehilfen). Der Webseitenbetreiber bleibt auch in dem Fall weiterhin für den Schutz Ihrer Daten verantwortlich. Wir beauftragen Unternehmen insbesondere in folgenden Bereichen: IT, Vertrieb, Marketing, Finanzen, Beratung, Kundenservice, Personalwesen und Logistik. An Kooperationspartner, die in eigener Verantwortung Leistungen für Sie bzw. im Zusammenhang mit Ihrer Registrierung erbringen. Dies ist der Fall, wenn Sie Leistungen solcher Partner bei uns beauftragen oder wenn Sie in die Einbindung des Partners einwilligen oder wenn wir den Partner aufgrund einer gesetzlichen Erlaubnis einbinden.</p>

    <h2>§10 Aufgrund gesetzlicher Verpflichtung</h2>
    <p>In bestimmten Fällen sind wir gesetzlich verpflichtet, bestimmte Daten an die anfragende staatliche Stelle zu übermitteln. Beispiel: Nach Vorlage eines Gerichtsbeschlusses sind wir gemäß § 101 Urheberrechtsgesetz verpflichtet, Inhabern von Urheber- und Leistungsschutzrechten Auskunft über Kunden zu geben, die urheberrechtlich geschützte Werke in Internet-Tauschbörsen angeboten haben sollen.</p>

    <h2>§11 Wo werden meine Daten verarbeitet?</h2>
    <p>Findet eine Verarbeitung Ihrer Daten in Ausnahmefällen auch in Ländern außerhalb der Europäischen Union (in sog. Drittstaaten) statt, geschieht dies, a) soweit Sie hierin ausdrücklich eingewilligt haben (Art. 49 Abs. 1a DSGVO). (In den meisten Ländern außerhalb der EU entspricht das Datenschutzniveau nicht den EU Standards. Dies betrifft insbesondere umfassende Überwachungs- und Kontrollrechte staatlicher Behörden, z.B. in den USA, die in den Datenschutz der europäischen Bürgerinnen und Bürger unverhältnismäßig eingreifen, b) oder soweit es für unsere Leistungserbringung Ihnen gegenüber erforderlich ist (Art. 49 Abs. 1b DSGVO), c) oder soweit es gesetzlich vorgesehen ist (Art. 6 Abs. 1c DSGVO). Darüber hinaus erfolgt eine Verarbeitung Ihrer Daten in Drittstaaten nur, soweit durch bestimmte Maßnahmen sichergestellt ist, dass hierfür ein angemessenes Datenschutzniveau besteht (z.B. Angemessenheitsbeschluss der EU-Kommission oder sog. geeignete Garantien, Art. 44ff. DSGVO).</p>

    <h2>§12 Recht auf Datenübertragbarkeit</h2>
    <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

    <h2>§13 Auskunft, Berichtigung und Löschung</h2>
    <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.</p>

    <h2>§14 Recht auf Einschränkung der Verarbeitung</h2>
    <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
    Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
    Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
    Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
    Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
    Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.</p>

    <h2>§15 SSL- bzw. TLS-Verschlüsselung</h2>
    <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS- Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>

    <h2>§16 Datenerfassung auf dieser Website: Anfrage per E-Mail, Telefon oder Telefax</h2>
    <p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar. Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt. Stand der Datenschutzhinweise: Januar 2024</p>
    </div>
    </div>
  );
};

export default DataProtection;
