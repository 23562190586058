import React, { useContext, useState, useEffect } from 'react';
import '../../styles/global.css';
import 'boxicons';
import { RiBuilding2Line, RiUserLine, RiQrCodeLine } from 'react-icons/ri';
import { IoCheckmarkCircleSharp, IoAlertCircleSharp } from 'react-icons/io5';
import { useSpring, animated } from 'react-spring';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import db, { auth } from '../../backend/firebase/firebaseConfig';
import { doc, getDoc, collection, query, where, getDocs  } from 'firebase/firestore';
import Settings from './Settings';
import ManageCompanies from './ManageCompanies';
import ManageBranches from './ManageBranches';
import ManageDepartments from './ManageDepartments';
import QRCodesToBeUsed from './InactiveQRCodes';
import ViewQRCodeIdentity from './ViewQRCodeIdentity';
import ActiveQRCodes from './ActiveQRCodes';
import UserHistory from './UserHistory';
import NavigationSideBar from './NavigationSideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog,faQrcode, faHome, faTachometerAlt, faBuilding, faCodeBranch, faSitemap, faIdCard, faHistory } from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].dashboard;
  const location = useLocation();
  const updateMessage = location.state?.updateMessage;

  const [statistics, setStatistics] = useState({
    adminCompanies: 0,
    userCompanies: 0,
    adminBranches: 0,
    userBranches: 0,
    adminBranchDepartments: 0,
    userBranchDepartments: 0,
    adminCompanyDepartments: 0,
    userCompanyDepartments: 0,
    activeQRCodes: 0,
    inactiveQRCodes: 0,
    deletedQRCodes: 0,
  });


  useEffect(() => {
    const fetchStatistics = async () => {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const userRef = doc(db, "users", userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Fetch statistics for companies
          const AdminCompaniesQuery = query(collection(db, "companies"), where("companyAdmins", "array-contains", userId));
          const AdminCompaniesSnapshot = await getDocs(AdminCompaniesQuery);
          const adminCompanies = AdminCompaniesSnapshot.size;
          const userCompaniesQuery = query(collection(db, "companies"), where("companyUsers", "array-contains", userId));
          const userCompaniesSnapshot = await getDocs(userCompaniesQuery);
          const userCompanies = userCompaniesSnapshot.size;
          // Fetch statistics for branches
          const adminBranchesQuery = query(collection(db, "branches"), where("branchAdmins", "array-contains", userId));
          const adminBranchesSnapshot = await getDocs(adminBranchesQuery);
          const adminBranches = adminBranchesSnapshot.size;
          const userBranchesQuery = query(collection(db, "branches"), where("branchUsers", "array-contains", userId));
          const userBranchesSnapshot = await getDocs(userBranchesQuery);
          const userBranches = userBranchesSnapshot.size;
          // Fetch statistics for company departments
          const adminCompanyDepartmentsQuery = query(collection(db, "companyDepartments"), where("departmentAdmins", "array-contains", userId));
          const adminCompanyDepartmentSnapshot = await getDocs(adminCompanyDepartmentsQuery);
          const adminCompanyDepartments = adminCompanyDepartmentSnapshot.size;
          const userCompanyDepartmentsQuery = query(collection(db, "companyDepartments"), where("departmentUsers", "array-contains", userId));
          const userCompanyDepartmentsSnapshot = await getDocs(userCompanyDepartmentsQuery);
          const userCompanyDepartments = userCompanyDepartmentsSnapshot.size;
          // Fetch statistics for branch departments
          const adminBranchDepartmentsQuery = query(collection(db, "branchDepartments"), where("departmentAdmins", "array-contains", userId));
          const adminBranchDepartmentSnapshot = await getDocs(adminBranchDepartmentsQuery);
          const adminBranchDepartments = adminBranchDepartmentSnapshot.size;
          const userBranchDepartmentsQuery = query(collection(db, "branchDepartments"), where("departmentUsers", "array-contains", userId));
          const userBranchDepartmentsSnapshot = await getDocs(userBranchDepartmentsQuery);
          const userBranchDepartments = userBranchDepartmentsSnapshot.size;
          // Fetch statistics for active QR codes
          const activeQRCodesQuery = query(collection(db, "activeQRCodes"), where("IDOfOwner", "==", userId));
          const activeQRCodesSnapshot = await getDocs(activeQRCodesQuery);
          const activeQRCodes = activeQRCodesSnapshot.size;
          // Fetch statistics for inactive QR codes
          const inactiveQRCodesQuery = query(collection(db, "inactiveQRCodes"), where("IDOfOwner", "==", userId));
          const inactiveQRCodesSnapshot = await getDocs(inactiveQRCodesQuery);
          const inactiveQRCodes = inactiveQRCodesSnapshot.size;
          // Fetch statistics for deleted QR codes
          const deletedQRCodesQuery = query(collection(db, "inactiveQRCodes"), where("deactivateBy", "==", userId));
          const deletedQRCodesSnapshot = await getDocs(deletedQRCodesQuery);
          const deletedQRCodes = deletedQRCodesSnapshot.size;

          // Update statistics state
          setStatistics({
            adminCompanies,
            userCompanies,
            adminBranches,
            userBranches,
            adminCompanyDepartments,
            userCompanyDepartments,
            adminBranchDepartments,
            userBranchDepartments,
            activeQRCodes,
            inactiveQRCodes,
            deletedQRCodes
          });
        }
      }
    };

    fetchStatistics();
  }, []);

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 }
  });
  
  const [activeTab, setActiveTab] = useState('');
  const navigate = useNavigate();
  const { tab } = useParams(); // Get the current tab from the URL
  const defaultView = '';

      // Set the active tab based on the URL, defaulting to the main dashboard view
      useEffect(() => {
        const checkIdentityVerification = async () => {
          const user = auth.currentUser;
          if (user) {
            const userRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(userRef);
    
            if (docSnap.exists()) {
              const userData = docSnap.data();
              if (userData.identityVerified === false) {
                navigate('/account-verification');
                return; // Prevent further execution
              }
            }
          }
    
          // Set the active tab based on the URL parameter
          setActiveTab(tab || defaultView);
        };

        
    
        checkIdentityVerification();
      }, [navigate, tab, defaultView]);


  const handleActivatePageClick = () => {
    navigate('/activate-qr-code');
  };
  const handlePurchasePageClick = () => {
    navigate('/purchase-qr-code');
  };

  const handleNavigate = (direction) => {
    navigate(`/${direction}`);
  };
  const renderContent = () => {
    if (activeTab === defaultView) {
      return (
        <div>
          {updateMessage && <p>{updateMessage}</p>}
          <section className="dashboard">

        <div className="dash-content">
            <div className="overview">
                <div className="title">
                    <h2>{t.dashboardHeader1}</h2>
                    <p> {t.dashboardP1}</p>
                </div>
                <div className="boxes">
                <a onClick={() => handleNavigate('dashboard/activeQRCodes')} className="box box1">
                    <i><FontAwesomeIcon icon={faQrcode} className="box-icon"  color='white' size='lg'/></i>
                        <span className="text">{t.youHave}</span>
                        <span className="number">{statistics.activeQRCodes}</span>
                        <span className="text">{t.activeQRCodesnumber}</span>
                    </a>
                    <a onClick={() => handleNavigate('dashboard/inactiveQRCodes')} className="box box1">
                    <i><FontAwesomeIcon icon={faQrcode} className="box-icon"  color='white' size='lg'/></i>
                        <span className="text">{t.youHave}</span>
                        <span className="number">{statistics.inactiveQRCodes}</span>
                        <span className="text">{t.inactiveQRCodesnumber}</span>
                    </a>
                    <a onClick={() => handleNavigate('dashboard/history')} className="box box1">
                    <i><FontAwesomeIcon icon={faHistory} className="box-icon"  color='white' size='lg'/></i>
                        <span className="text">{t.youHave}</span>
                        <span className="number">{statistics.deletedQRCodes}</span>
                        <span className="text">{t.deletedQRCodesnumber}</span>
                    </a>
                    <a onClick={() => handleNavigate('dashboard/companies')} className="box box1">
                    <i><FontAwesomeIcon icon={faBuilding} className="box-icon"  color='white' size='lg'/></i>
                        <span className="text">{t.youAre}</span>
                        <span className="number">{statistics.adminCompanies} {t.times}</span>
                        <span className="text">{t.companyAdmin}</span>
                        <span className="number">{statistics.userCompanies} {t.times}</span>
                        <span className="text">{t.companyUser}</span>
                    </a>
                    <a onClick={() => handleNavigate('dashboard/branches')} className="box box1">
                    <i><FontAwesomeIcon icon={faCodeBranch} className="box-icon"  color='white' size='lg'/></i>
                        <span className="text">{t.youAre}</span>
                        <span className="number">{statistics.adminBranches} {t.times}</span>
                        <span className="text">{t.branchAdmin}</span>
                        <span className="number">{statistics.userBranches} {t.times}</span>
                        <span className="text">{t.branchUser}</span>
                    </a>
                    <a onClick={() => handleNavigate('dashboard/departments')} className="box box1">
                    <i><FontAwesomeIcon icon={faSitemap} className="box-icon"  color='white' size='lg'/></i>
                        <span className="text">{t.youAre}</span>
                        <span className="number">{statistics.adminBranchDepartments + statistics.adminCompanyDepartments} {t.times}</span>
                        <span className="text">{t.departmentAdmin}</span>
                        <span className="number">{statistics.userBranchDepartments + statistics.userCompanyDepartments} {t.times}</span>
                        <span className="text">{t.departmentUser}</span>
                    </a>
                    
                </div>
            </div>
        </div>
    </section>
        </div>
      );
    }
    switch (activeTab) {
      case 'settings':
        return <Settings />;
      case 'companies':
        return <ManageCompanies />;
      case 'branches':
        return <ManageBranches />;
      case 'departments':
        return < ManageDepartments />;
      case 'inactiveQRCodes':
        return<QRCodesToBeUsed/>;
        case 'qrCodeIdentity':
          return <ViewQRCodeIdentity/>;
      case 'activeQRCodes':
        return <ActiveQRCodes/>;
      case 'history':
        return <UserHistory/>;
      default:
        return null;
    }
  };


  
  return (
    <div>
    <link flex href="https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css" rel="stylesheet" />
    <link rel="stylesheet" href="https://unicons.iconscout.com/release/v4.0.0/css/line.css"/>


    <div className="dashboard-page">

      <NavigationSideBar/>
      <div className="dashboard-content">
        {renderContent()}
      </div>
      <div className="dashboard-actions">
        {/*<button className="button-activate-qr-code" onClick={handleActivatePageClick}>{t.activateQRCode}</button>
        <button className="button-purchase-qr-code" onClick={handlePurchasePageClick}>{t.purchaseQRCode}</button>*/}
      </div>
    </div>

    </div>
  );
};

export default Dashboard;
