import React, { createContext, useState } from 'react';
import en from '../translations/en/en.json';
import de from '../translations/de/de.json';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  var userLang = navigator.language || navigator.userLanguage; 
  const getDefaultLanguage = () => userLang.includes('de') ? 'de' : 'en';
  const [language, setLanguage] = useState(getDefaultLanguage());
  const translations = { en, de };

  const switchLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, translations, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
