import React, { useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import UserList from './UserList';
import QRCodeList from './QRCodeList';
import CompanyList from './CompanyList';
import '../../styles/global.css';

const AdminPanel = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].adminPanel;
  const navigate = useNavigate();
  const auth = getAuth();

  // Admin UID
  const adminUID = "2Z1QlUM5fWYAXINrwPwcfMk62vt1"; // Replace with the actual admin UID

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user || (user.uid !== adminUID && user.admin == true)) {
        navigate('/'); // Redirect to home if not the admin
      }
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, [navigate]);

  return (
    <div className="admin-panel">
      <h1>{t.title}</h1>
      <Link to="/user-list">
        <h2>User List</h2>
      </Link>
      <Link to="/qrcode-list">
        <h2>QR Code List</h2>
      </Link>
      <Link to="/company-list">
        <h2>Company List</h2>
      </Link>
    </div>
  );
};

export default AdminPanel;
