import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/login/Login';
import ForgotPassword from './pages/login/ForgotPassword';

import Registeration from './pages/registration/Registration';
import AccountVerification from './pages/registration/Account-Verification';
import TermsConditions from './pages/general/terms-conditions/TermsConditions';
import Imprint from './pages/general/imprint/Imprint';
import DataProtection from './pages/general/data-protection/DataProtection';
import MobileAppDescription from './pages/general/mobile-app-description/MobileAppDescription';

import NotFoundPage from './pages/general/not-found-page/NotFoundPage';
import ContactPage from './pages/general/contact/ContactPage';
import { LanguageProvider }  from './contexts/LanguageContext';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import HomePage from './pages/homepage/Homepage';

import IntroVideoPage from './pages/general/tutorials/Intro-Video';

import CheckQRCode from './pages/check-qr-code/CheckQRCode';
import ActivateQRCode from './pages/dashboard/activate-qr-code/ActivateQRCode';
import PurchaseQRCode from './pages/dashboard/purchase-qr-code/PurchaseQRCode';

import Dashboard from './pages/dashboard/Dashboard';
import AdminPanel from './pages/admin-panel/AdminPanel';
import CompanyList from './pages/admin-panel/CompanyList';
import QRCodeList from './pages/admin-panel/QRCodeList';
import UserList from './pages/admin-panel/UserList';

import DesktopOnly from './components/DesktopOnly'; // Import the DesktopOnly component


function App() {

  return (
    <LanguageProvider>
      <Router>
      <Header /> {/* Includes the header here */}

      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Registeration />} />
          <Route path="/account-verification" element={<ProtectedRoute element={<AccountVerification />} />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/data-protection" element={<DataProtection />} />
          <Route path="/404-page-not-found" element={<NotFoundPage />} />
          <Route path="/mobile-app" element={<MobileAppDescription />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/check-qr-code" element={<CheckQRCode />} />

          <Route path="/activate-qr-code" element={<ProtectedRoute element={<ActivateQRCode />} />} />
          <Route path="/purchase-qr-code" element={<ProtectedRoute element={<PurchaseQRCode />} />} />
          <Route path="/admin-panel" element={<ProtectedRoute element={<AdminPanel />} />} />
          <Route path="/company-list" element={<ProtectedRoute element={<CompanyList />} />} />
          <Route path="/qrcode-list" element={<ProtectedRoute element={<QRCodeList />} />} />
          <Route path="/user-list" element={<ProtectedRoute element={<UserList />} />} />

          <Route path="/dashboard" element={<ProtectedRoute element={<DesktopOnly><Dashboard /></DesktopOnly>} />} />
          <Route path="/dashboard/:tab" element={<ProtectedRoute element={<DesktopOnly><Dashboard /></DesktopOnly>} />} />


          <Route path="/intro-video" element={<IntroVideoPage />} />

          <Route path="*" element={<NotFoundPage />} />

        </Routes>
      <Footer /> {/* Includes the header here */}

      </Router>
    </LanguageProvider>
  );
}

export default App;
