import React, {useContext} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import { LanguageContext } from '../contexts/LanguageContext.js';

import '../styles/global.css'
import aiPhotoOfUserUsingLogiHash from '../assets/images/user_using_LogiHash.png';

const DesktopOnly = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].desktopOnly;
  if (!isDesktop) {
    return (
        <div className='white-page-content'>
      <div className="desktop-only-container">
        <img src={aiPhotoOfUserUsingLogiHash} alt="LogiHash Desktop User" className="logihash-desktop-user" />
        {t.thisPageIsVisibleOnlyOnDesktop}
        <Link to="/"> {t.youCanGoBackToHome}</Link>
      </div>
      </div>
    );
  }

  return children;
};

export default DesktopOnly;
