import React, { useContext, useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import '../../../styles/global.css';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import db, { auth } from '../../../backend/firebase/firebaseConfig';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons';
import EnvelopPicture from '../../../assets/images/envelop-picture.png';
import ParcelPicture from '../../../assets/images/parcel-picture.png';

const PurchaseQRCode = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].dashboard;
  const [qrCode, setQRCode] = useState('');
  const [productDetails, setProductDetails] = useState({});
  const [envelopeScanText, setEnvelopeScanText] = useState(t.scanTextOption1);
  const [parcelScanText, setParcelScanText] = useState(t.scanTextOption1);

  const navigate = useNavigate();

  useEffect(() => {
    const checkIdentityVerification = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists() && docSnap.data().identityVerified === false) {
          navigate('/account-verification');
        }
      }
    };

    checkIdentityVerification();
  }, [navigate]);


  const handleQRCodeClick = (qrCodeUID) => {
    navigate(`/activate-qr-code?q=${qrCodeUID}`);
  };
  const handlePurchase = async (product, size, scanText) => {
    // Generate a unique QR code value
    const uniqueCode = `QR-${Date.now()}`;
    setQRCode(uniqueCode);

    const user = auth.currentUser;
    if (user) {
      const qrCodeData = {
        qrCodeUID: uniqueCode,
        creationTimestamp: serverTimestamp(), // Add server timestamp here
        lastChangeTimestamp: serverTimestamp(),
        IDOfOwner: [user.uid],
        active: false,
        new: true,
        deactivateBy: '',
        senderName: '',
        senderStreet: '',
        senderHouseNumber: '',
        senderPostalCode: '',
        senderCity: '',
        senderState: '',
        recipientName: '',
        recipientStreet: '',
        recipientHouseNumber: '',
        recipientPostalCode: '',
        recipientCity: '',
        recipientState: '',
        recipientLogiHashID: '',
        recipientPhoneNumber: '',
        status: '',
        notificationTime: '',
        qrCodeProduct: product,
        qrCodeProductSize: size,
        qrCodeWarnings: [],
        scanText: scanText, // Add scanText here
        numberOfScans: 0
        //public key Post
        //public key recepiant
      };


        // Create document in 'inactiveQRCodes' collection
  await setDoc(doc(db, 'inactiveQRCodes', uniqueCode), qrCodeData);
  setProductDetails({ product, size });
}
};

return (
  <div className="white-page-content">
  <div className="purchase-qr-code-page">
    <h1>{t.titlePurchaseQrCode}</h1>
    <p className="mobile-app-note-container">{t.descriptionPurchaseQrCode}</p>

    {!qrCode && (
          <div className="image-wrapper">
            <div className="envelope-image-wrapper">
            <img src={EnvelopPicture} alt="Postal Envelope" className="envelope-image"/>
            <label htmlFor="envelopeScanText" className='message-label'>{t.chooseMessage}: </label>
            <select
              id="envelopeScanText"
              name="envelopeScanText"
              onChange={(e) => setEnvelopeScanText(e.target.value)}
              value={envelopeScanText}
              className="message-select"
              required
            >
              <option value="scanTextOption1">{t.scanTextOption1}</option>
              <option value="scanTextOption2">{t.scanTextOption2}</option>
              <option value="scanTextOption3">{t.scanTextOption3}</option>
            </select>
              <button className='button-purchase' onClick={() => handlePurchase('ENVELOPE', 'DIN_C6/5', envelopeScanText)}>{t.purchaseButtonEnvelope} <FontAwesomeIcon icon={faShoppingCart} /></button>
            </div>
            <div className="parcel-image-wrapper">
            <img src={ParcelPicture} alt="Parcel Cartoon" className="parcel-image"/>
            <label htmlFor="envelopeScanText" className='message-label'>{t.chooseMessage} </label>
              <select
                id="envelopeScanText"
                name="parcelScanText"
                onChange={(e) => setParcelScanText(e.target.value)}
                value={parcelScanText}
                className="message-select"
                required
              >
                <option value="scanTextOption1">{t.scanTextOption1}</option>
                <option value="scanTextOption2">{t.scanTextOption2}</option>
                <option value="scanTextOption3">{t.scanTextOption3}</option>
              </select>
              <button className='button-purchase' onClick={() => handlePurchase('PARCEL', '2_KG', parcelScanText)}>{t.purchaseButtonParcel} <FontAwesomeIcon icon={faShoppingCart} /></button>
            </div>
          </div>
        )}

    {qrCode && (
      <div className="image-wrapper">
        {productDetails.product === 'ENVELOPE' && (
          <div className="envelope-image-wrapper">
            <img src={EnvelopPicture} alt="Postal Envelope" className="envelope-image"/>
            <div  id={`qrCodeToPrint-envelope-${qrCode}`}>
              <QRCode className="qr-code-overlay-envelope" value={`https://logihash.trustecvalley.com/check-qr-code?q=${qrCode}`} size={50} />
              <p className="qr-code-text-envelope">{qrCode}</p>
              <p className="qr-code-message-envelope">{t[envelopeScanText] || t.scanTextOption1}</p>
            </div>
          </div>
        )}
        {productDetails.product === 'PARCEL' && (
          <div className="parcel-image-wrapper">
            <img src={ParcelPicture} alt="Parcel Cartoon" className="parcel-image"/>
            <div  id={`qrCodeToPrint-parcel-${qrCode}`}>
              <QRCode className="qr-code-overlay-parcel" value={`https://logihash.trustecvalley.com/check-qr-code?q=${qrCode}`} size={50} />
              <p className="qr-code-text-parcel">{qrCode}</p>
              <p className="qr-code-message-parcel">{t[parcelScanText] || t.scanTextOption1}</p>
            </div>
          </div>
        )}
        <p>{t.qrCodeValue} : {qrCode}</p>
        <div className='button-activate-container'>
        <button className='button-activate' onClick={() => handleQRCodeClick(qrCode)}>{t.activateQRCode}</button>
        </div>
        <p>{t.qrCodeNote}</p>
      </div>
    )}
  </div>
  </div>
);
};

export default PurchaseQRCode;