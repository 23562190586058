import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCog,faSignInAlt, faSignOutAlt, faShoppingCart, faQrcode, faHome, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import LanguageSwitcher from './LanguageSwitcher';
import useOutsideClick from '../../hooks/useOutsideClick';
import db from '../../backend/firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import '../../styles/global.css';

const Header = () => {
  const navigate = useNavigate();
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].header;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profilePictureURL, setProfilePictureURL] = useState('');

  const auth = getAuth();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [isAdmin, setIsAdmin] = useState(false); // New state for admin check

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoggedIn(!!user);
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setFirstName(docSnap.data().firstName); // Set the firstName
          setLastName(docSnap.data().lastName); // Set the lastName
          setIsAdmin(docSnap.data().admin || false); // Check if user is admin
          setProfilePictureURL(docSnap.data().profilePictureURL || ''); // Set profile picture URL if it exists

        }
      }
    });
    return unsubscribe; // Cleanup subscription on unmount
  },[auth]);


  const dropdownRef = useRef(null); // Ref for the dropdown
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // Close dropdown function
  const closeDropdown = () => {
    setShowDropdown(false);
  };

  // Using the custom hook

  useOutsideClick(dropdownRef, closeDropdown);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, [auth]);


  const handleLoginClick = () => {
    if (isLoggedIn) {
      signOut(auth).then(() => {
        setShowDropdown(false); // Close the dropdown menu
        navigate('/');
      }).catch((error) => {
        console.error('Error signing out:', error);
      });
    } else {
      navigate('/login');
    }
  };


  

  return (
    <header>
    <nav className="navbar flex">
      <Link to="/" className="header-link"><FontAwesomeIcon icon={faHome} /> {t.home}</Link>
      {isLoggedIn && (
        <Link to="/dashboard" className="header-link"> <FontAwesomeIcon icon={faTachometerAlt} /> {t.dashboard}</Link>
      )}
      {/* Other header content */}

      <Link to="/check-qr-code" className="header-link"> <FontAwesomeIcon icon={faQrcode} /> {t.checkNowQRCode} </Link>
 
      {isLoggedIn && isAdmin && (
                <button type="button" className="button-admin-panel" onClick={() => navigate('/admin-panel')}>
                  {t.goAdminPanel}
                </button>
              )}
      {isLoggedIn ? (
        <div className="user-dropdown" ref={dropdownRef}>
          <button type="button" className="button-user-icon" onClick={toggleDropdown}>
            {profilePictureURL ? (
              <img src={profilePictureURL} alt="Profile" className="profile-picture-icon" />
            ) : (
              <FontAwesomeIcon icon={faUserCircle} />
            )}
          </button>
          
          {showDropdown && (
            <div className="dropdown-menu">
              <div className="dropdown-username">
                {t.loggedInAs}: {firstName +" " + lastName}
              </div>
              <Link to="/dashboard/settings" className="dropdown-button-settings" onClick={closeDropdown}>
                <FontAwesomeIcon icon={faCog} /> {t.settings}
              </Link>
              {isLoggedIn && isAdmin && (
                <button type="button" className="button-submit" onClick={() => navigate('/admin-panel')}>
                  {t.goAdminPanel}
                </button>
              )}
              <button type="button" className="button-logout" onClick={handleLoginClick}>
                <FontAwesomeIcon icon={faSignOutAlt} /> {t.logout}
              </button>
            </div>
          )}
        </div>
      ) : (
        /*<button type="button" className="button-login" onClick={handleLoginClick}>
          {t.loginNow}
        </button>*/

        <Link to="/login" className="header-link">
        <FontAwesomeIcon icon={faSignInAlt} /> {t.loginNow}
        </Link>

        
      )}
      <Link to="/purchase-qr-code" className="header-icon" title={t.onlineShop}>
        <FontAwesomeIcon icon={faShoppingCart} />
      </Link>

      <LanguageSwitcher />
      </nav>
    </header>
  );
};

export default Header;