import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import '../../../styles/global.css';

const TermsConditions = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].termsConditions;

  return (
    <div className="white-page-content">
    <div className="terms-conditions">
    <h1>Allgemeine Geschäftsbedingungen (AGB) für die Nutzung der Dienstleistungen von Trustec Valley</h1>

    <h2>1. Unternehmensangaben:</h2>
    <p>Trustec Valley Basali & Genedy GbR, Universitätsstraße 3, 56070 Koblenz,
    E-Mail: <a href="mailto:info@trustecvalley.com">info@trustecvalley.com</a> </p>

    <h2>2. Beschreibung des Dienstes:</h2>
    <p>Trustec Valley bietet seinen Kunden einen innovativen Service, der es ermöglicht, QR-Codes zu generieren, um den Versand von iefen und Paketen sicherzustellen. Der Dienst gewährleistet, dass Sendungen nur unter dem Namen des berechtigten Absenders verschickt werden können. Kunden können über die Website/App personalisierte QR-Codes erstellen, die mit ihren Versandinformationen verknüpft sind. Dies dient der Sicherheit und Authentifizierung des Versandprozesses.</p>

    <h2>3. Lieferung und Kosten:</h2>
    <p>Die Lieferung erfolgt je nach Verfügbarkeit der Dienstleistung innerhalb der festgelegten Lieferzeiten. Die Kosten für den Versand variieren abhängig von Größe, Gewicht und Lieferort der Sendung. Trustec Valley bedient bestimmte Gebiete, die vorab auf der Website angezeigt werden.</p>

    <h2>4. Zahlungsmethoden:</h2>
    <p>Kunden können ihre Bestellungen bequem per Banküberweisung, PayPal, Bankeinzug oder Kreditkarte bezahlen. Es fallen keine Bearbeitungsgebühren an, außer etwaige Gebühren von Drittanbietern (z.B. bei Kreditkartenzahlungen). Mindestens eine der angebotenen Zahlungsmethoden ist kostenfrei.</p>

    <h2>5. Vertragssprache:</h2>
    <p>Der Vertrag wird in der jeweiligen Landessprache abgeschlossen. Kunden werden darüber informiert, in welcher Sprache der Service und der Vertrag verfügbar sind.</p>

    <h2>6. Kundendienst:</h2>
    <p>Für Anfragen und Unterstützung steht der Kundendienst von Trustec Valley zur Verfügung. Kontaktdaten:
    Ozan Basali,
    Trustec Valley Basali & Genedy GbR, Universitätsstraße 3, 56070 Koblenz, 
    <a href="mailto:info@trustecvalley.com">info@trustecvalley.com</a>
    Telefon: +49 173-3450556</p>

    <h2>7. Garantien:</h2>
    <p>Trustec Valley informiert über verfügbare rechtliche Garantien im Rahmen der gesetzlichen Bestimmungen.</p>

    <h2>8. Rücktrittsrecht:</h2>
    <p>Gemäß EU- und deutschem Recht haben Veraucher das Recht, innerhalb von 14 Tagen von online abgeschlossenen Verträgen zurückzutreten. Kunden müssen über ihr Widerrufsrecht informiert werden, einschließlich der Möglichkeiten zur Ausübung und der Rücksendekosten. Trustec Valley erstattet den vollen Kaufpreis bei ordnungsgemäßem Widerruf.</p>

    <h2>9. Hinweis zur Rückgaberegelung:</h2>
    <p>Die Informationen zum Rücktrittsrecht werden auf einer separaten, gut zugänglichen Seite veröffentlicht, üblicherweise unter der Fußzeile "Widerrufsrecht". Diese Informationen sind deutlich markiert, um Kunden auf ihre Rechte hinzuweisen.</p>

    <h2>10. Verbindliche Bestellung:</h2>
    <p>Die Schaltfläche zur verbindlichen Bestellung trägt den Titel "zahlungspflichtig bestellen" oder eine gleichwertige Formulierung. Dieser Ausdruck soll eindeutig signalisieren, dass Kunden sich gesetzlich verpflichten und einen zahlungspflichtigen Vertrag abschließen.</p>

    <p>Die Nutzung der Dienstleistungen von Trustec Valley unterliegt diesen AGB. Kunden werden gebeten, die AGB sorgfältig zu lesen und zu akzeptieren, bevor sie den Service in Anspruch nehmen. Trustec Valley behält sich das Recht vor, diese AGB nach eigenem Ermessen zu ändern.</p>
    </div>
    </div>
  );
};

export default TermsConditions;
