import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import LoginForm from './LoginForm';
import '../../styles/global.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Login = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].login;
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is logged in, redirect to dashboard
        navigate('/dashboard');
      }
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, [navigate]);

  return (
    <div className='white-page-content'>
    <div className="login-page">
      <h1>{t.loginMessage}</h1>
      <LoginForm />
    </div>
    </div>
  );
};

export default Login;
