import React, { useContext, useEffect } from 'react';
import '../../styles/global.css';
import 'boxicons'
import { LanguageContext } from '../../contexts/LanguageContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog,faQrcode, faHome, faTachometerAlt, faBuilding, faCodeBranch, faSitemap, faIdCard, faHistory } from '@fortawesome/free-solid-svg-icons';

import TrustecValleyLogo from '../../assets/images/Trustec_Valley_Logo.png';

const NavigationSideBar = () => {
    const { language, translations } = useContext(LanguageContext);
    const t = translations[language].dashboard;  
  
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
  
    const handleTabChange = (newTab) => {
        navigate(`/dashboard/${newTab}`);
    };

    const handleNavigate = (direction) => {
      navigate(`/${direction}`);
    };

    const isCurrentPage = (path) => {
      return currentPath === path; };

  
    useEffect(() => {
      // Selecting the sidebar and buttons
      const sidebar = document.querySelector(".sidebar");
      const sidebarOpenBtn = document.querySelector("#sidebar-open");
      const sidebarCloseBtn = document.querySelector("#sidebar-close");
      const sidebarLockBtn = document.querySelector("#lock-icon");
  
      // Function to toggle the lock state of the sidebar
      const toggleLock = () => {
        sidebar.classList.toggle("locked");
        // If the sidebar is not locked
        if (!sidebar.classList.contains("locked")) {
          sidebar.classList.add("hoverable");
          sidebarLockBtn.classList.replace("bx-lock-alt", "bx-lock-open-alt");
        } else {
          sidebar.classList.remove("hoverable");
          sidebarLockBtn.classList.replace("bx-lock-open-alt", "bx-lock-alt");
        }
      };
  
      // Function to hide the sidebar when the mouse leaves
      const hideSidebar = () => {
        if (sidebar.classList.contains("hoverable")) {
          sidebar.classList.add("close");
        }
      };
  
      // Function to show the sidebar when the mouse enters
      const showSidebar = () => {
        if (sidebar.classList.contains("hoverable")) {
          sidebar.classList.remove("close");
        }
      };
  
      // Function to show and hide the sidebar
      const toggleSidebar = () => {
        sidebar.classList.toggle("close");
      };
  
      // If the window width is less than 800px, close the sidebar and remove hoverability and lock
      if (window.innerWidth < 800) {
        sidebar.classList.add("close");
        sidebar.classList.remove("locked");
        sidebar.classList.remove("hoverable");
      }
  
      // Adding event listeners to buttons and sidebar for the corresponding actions
      sidebarLockBtn.addEventListener("click", toggleLock);
      sidebar.addEventListener("mouseleave", hideSidebar);
      sidebar.addEventListener("mouseenter", showSidebar);
      sidebarOpenBtn.addEventListener("click", toggleSidebar);
      sidebarCloseBtn.addEventListener("click", toggleSidebar);
  
      // Clean up event listeners when the component unmounts
      return () => {
        sidebarLockBtn.removeEventListener("click", toggleLock);
        sidebar.removeEventListener("mouseleave", hideSidebar);
        sidebar.removeEventListener("mouseenter", showSidebar);
        sidebarOpenBtn.removeEventListener("click", toggleSidebar);
        sidebarCloseBtn.removeEventListener("click", toggleSidebar);
      };
    }, []);
    
    return (
    
        <div>
        <link flex href="https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css" rel="stylesheet" />
        <nav className="sidebar locked">
          <div className="logo_items flex">
            <span className="nav_image">
            <a onClick={() => handleNavigate('')} ><img src={TrustecValleyLogo} alt="logo_img" className="logo-image" /></a>
            </span>
            <a onClick={() => handleNavigate('')} ><span className="logo_name">LogiHash</span></a>
            <i className="bx bx-lock-alt" id="lock-icon" title="Unlock Sidebar"></i>
            <i className="bx bx-x" id="sidebar-close"></i>
          </div>
  
          <div className="menu_container">
            <div className="menu_items">
              <ul className="menu_item">
  
              <li className="item">
                <a onClick={() => handleNavigate('')} className={`link flex ${isCurrentPage('/') ? 'active' : ''}`}>
                <i><FontAwesomeIcon icon={faHome} className="box-icon" color={`${isCurrentPage('/') ? 'white' : 'black'}`} /></i>
                  <span>{t.home}</span>
                </a>
              </li>
              <li className="item">
                <a onClick={() => handleTabChange('')} className={`link flex ${isCurrentPage('/dashboard/') ? 'active' : ''}`}>
                <i><FontAwesomeIcon icon={faTachometerAlt} className="box-icon" color={`${isCurrentPage('/dashboard/') ? 'white' : 'black'}`} /></i>
                  <span>{t.dashboard}</span>
                </a>
              </li>
              <li className="item">
                <a onClick={() => handleTabChange('activeQRCodes')} className={`link flex ${isCurrentPage('/dashboard/activeQRCodes') ? 'active' : ''}`}>
                <i><FontAwesomeIcon icon={faQrcode} className="box-icon" color={`${isCurrentPage('/dashboard/activeQRCodes') ? 'white' : 'black'}`} /></i>
                  <span>{t.activeQRCodes}</span>
                </a>
              </li>
              <li className="item">
                <a onClick={() => handleTabChange('inactiveQRCodes')} className={`link flex ${isCurrentPage('/dashboard/inactiveQRCodes') ? 'active' : ''}`}>
                <i><FontAwesomeIcon icon={faQrcode} className="box-icon" color={`${isCurrentPage('/dashboard/inactiveQRCodes') ? 'white' : 'black'}`} /></i>
                  <span>{t.qrCodesToBeUsed}</span>
                </a>
              </li>
              <li className="item">
                <a onClick={() => handleTabChange('companies')} className={`link flex ${isCurrentPage('/dashboard/companies') ? 'active' : ''}`}>
                <i><FontAwesomeIcon icon={faBuilding} className="box-icon" color={`${isCurrentPage('/dashboard/companies') ? 'white' : 'black'}`} /></i>
                  <span>{t.manageCompanies}</span>
                </a>
              </li>
              <li className="item">
                <a onClick={() => handleTabChange('branches')} className={`link flex ${isCurrentPage('/dashboard/branches') ? 'active' : ''}`}>
                <i><FontAwesomeIcon icon={faCodeBranch} className="box-icon" color={`${isCurrentPage('/dashboard/branches') ? 'white' : 'black'}`} /></i>
                  <span>{t.manageBranches}</span>
                </a>
              </li>
              <li className="item">
                <a onClick={() => handleTabChange('departments')} className={`link flex ${isCurrentPage('/dashboard/departments') ? 'active' : ''}`}>
                <i><FontAwesomeIcon icon={faSitemap} className="box-icon" color={`${isCurrentPage('/dashboard/departments') ? 'white' : 'black'}`} /></i>
                  <span>{t.manageDepartments}</span>
                </a>
              </li>
              <li className="item">
                <a onClick={() => handleTabChange('qrCodeIdentity')} className={`link flex ${isCurrentPage('/dashboard/qrCodeIdentity') ? 'active' : ''}`}>
                <i><FontAwesomeIcon icon={faIdCard} className="box-icon" color={`${isCurrentPage('/dashboard/qrCodeIdentity') ? 'white' : 'black'}`} /></i>
                  <span>{t.viewQRCodeIdentity}</span>
                </a>
              </li>
              <li className="item">
                <a onClick={() => handleTabChange('settings')} className={`link flex ${isCurrentPage('/dashboard/settings') ? 'active' : ''}`}>
                <i><FontAwesomeIcon icon={faCog} className="box-icon" color={`${isCurrentPage('/dashboard/settings') ? 'white' : 'black'}`} /></i>
                  <span>{t.settings}</span>
                </a>
              </li>
              <li className="item">
                <a onClick={() => handleTabChange('history')} className={`link flex ${isCurrentPage('/dashboard/history') ? 'active' : ''}`}>
                <i><FontAwesomeIcon icon={faHistory} className="box-icon" color={`${isCurrentPage('/dashboard/history') ? 'white' : 'black'}`} /></i>
                  <span>{t.history}</span>
                </a>
              </li>
              </ul>
  
            </div>
          </div>
        </nav>
  
        {/* Navbar */}
        <nav className="navbar flex">
          <i className="bx bx-menu" id="sidebar-open"></i>
        </nav>
      </div>  
    );
  };
  
  export default NavigationSideBar;
  