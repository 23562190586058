import React, {useContext} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../backend/firebase/firebaseConfig';
import { LanguageContext } from '../contexts/LanguageContext.js';

import '../styles/global.css'

const ProtectedRoute = ({ element: Component }) => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].protectedRoute;

  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();


  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    alert(t.requiredBeingLoggedIn)
    // Store the attempted URL for redirecting after successful login
    sessionStorage.setItem('redirectAfterLogin', location.pathname + location.search);
    return <Navigate to="/login" />;
  }

  return Component;
};

export default ProtectedRoute;