import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import 'react-phone-number-input/style.css';
import { LanguageContext } from '../../contexts/LanguageContext';
import db, { auth, storage } from '../../backend/firebase/firebaseConfig'; // Ensure storage is imported
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { updatePassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import '../../styles/global.css';

const Settings = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].dashboard;
  const [userData, setUserData] = useState({});
  const [editableData, setEditableData] = useState({});
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState('');
  const [updateMessage, setUpdateMessage] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUserData(docSnap.data());
          setEditableData({
            email: docSnap.data().email,
            street: docSnap.data().street,
            houseNumber: docSnap.data().houseNumber,
            postalCode: docSnap.data().postalCode,
            city: docSnap.data().city,
            state: docSnap.data().state,
            country: docSnap.data().country,
            phoneNumber: docSnap.data().phoneNumber,
            username: docSnap.data().username,
            gender: docSnap.data().gender,
          });
          setProfilePictureURL(docSnap.data().profilePictureURL || ''); // Ensure the URL is set if it exists
        }
      }
    };
    fetchUserData();
  }, [auth.currentUser]);

  const handleInputChange = (e) => {
    setEditableData({ ...editableData, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleProfilePictureChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = auth.currentUser;
      const userRef = doc(db, 'users', user.uid);

      // Update user data in Firestore
      await updateDoc(userRef, editableData);

      // Update password if newPassword is set
      if (newPassword) {
        await updatePassword(user, newPassword);
      }

      // Upload profile picture if selected
      if (profilePicture) {
        const fileExtension = profilePicture.name.split('.').pop();
        const storageRef = ref(storage, `UserDocuments/${user.uid}/${user.uid}_${editableData.username}_${Date.now()}_profile_picture.${fileExtension}`);
        await uploadBytes(storageRef, profilePicture);
        const downloadURL = await getDownloadURL(storageRef);

        // Update profile picture URL in Firestore
        await updateDoc(userRef, { profilePictureURL: downloadURL });
        setProfilePictureURL(downloadURL);
      }

      // Set confirmation message and navigate to dashboard
      setUpdateMessage(t.updateMessage);
      navigate('/dashboard', { state: { updateMessage: t.updateMessage } });
    } catch (error) {
      console.error(t.errorUpdateMessage, error);
      // Handle errors here
    }
  };

  const handleDeactivateAccount = async () => {
    if (window.confirm(t.accountDeactivationConfirmationMessage)) {
      const user = auth.currentUser;
      const userRef = doc(db, 'users', user.uid);

      // Call a Firebase Cloud Function to deactivate the user in Firebase Auth
      // Example: await callDeactivateUserFunction(user.uid);

      // Update the active field in Firestore
      await updateDoc(userRef, { disabled: true });

      alert(t.accountSuccessfullyDeactivated);
      // Handle any post-deactivation logic, like redirecting to a login page

      signOut(auth).then(() => {
        navigate('/');
      }).catch((error) => {
        console.error('Error signing out:', error);
      });
    }
  };

  return (
    <div className="settings-container">
      <h2>{t.settingsTitle}</h2>
      <form className="settings-form" onSubmit={handleSubmit}>
        <div className="user-info">
        <div className="profile-picture-container">
        {profilePictureURL ? (
          <img src={profilePictureURL} alt="Profile" className="profile-picture" />
        ) : (
          <FontAwesomeIcon icon={faUserCircle} className="profile-picture"/>
        )}
      </div>
          <div className="info-item"><strong>{t.firstName}:</strong> {userData.firstName}</div>
          <div className="info-item"><strong>{t.lastName}:</strong> {userData.lastName}</div>
          <div className="info-item"><strong>{t.dateOfBirth}:</strong> {userData.dateOfBirth}</div>
          <div className="info-item"><strong><Link to="/terms-and-conditions" target="_blank">{t.acceptGTC}</Link>:</strong> {userData.acceptGTC ? t.yes : t.no}</div>
          <div className="info-item"><strong>{t.uID}:</strong> {userData.uid}</div>
          <div className="info-item"><strong>{t.yourCustomerServicePIN}:</strong> {userData.customerServicePIN}</div>
          <div className="info-item"><strong>{t.myAccountActive}:</strong> {(userData.disabled === false) ? t.yes : t.no}</div>
          <div className="info-item"><strong>{t.identityVerified}:</strong> {(userData.identityVerified === true) ? t.yes : t.no}</div>
        </div>
        <button type="button" className='button-deactivate' onClick={handleDeactivateAccount}>{t.deactivateAccountButtonLabel}</button>


        {/* Editable fields */}
            {/*  <label htmlFor="gender">{t.gender}</label>
                <select name="gender" value={editableData.gender} onChange={handleInputChange}>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="non-binary">Non-binary</option>
    </select>  */}
                {/* Add other input fields for editable data similarly */}
        <label htmlFor="email">{t.email}</label>
        <input type="text" name="email" value={editableData.email} onChange={handleInputChange} />
        <label htmlFor="street">{t.street}</label>
        <input type="text" name="street" value={editableData.street} onChange={handleInputChange} />
        <label htmlFor="houseNumber">{t.houseNumber}</label>
        <input type="text" name="houseNumber" value={editableData.houseNumber} onChange={handleInputChange} />
        <label htmlFor="postalCode">{t.postalCode}</label>
        <input type="text" name="postalCode" value={editableData.postalCode} onChange={handleInputChange} />
        <label htmlFor="city">{t.city}</label>
        <input type="text" name="city" value={editableData.city} onChange={handleInputChange} />
        <label htmlFor="state">{t.state}</label>
        <input type="text" name="state" value={editableData.state} onChange={handleInputChange} />
        <label htmlFor="country">{t.country}</label>
        <input type="text" name="country" value={editableData.country} onChange={handleInputChange} />
        <label htmlFor="phoneNumber">{t.phoneNumber}</label>
        <input type="text" name="phoneNumber" value={editableData.phoneNumber} onChange={handleInputChange} />
        <label htmlFor="username">{t.username}</label>
        <input type="text" name="username" value={editableData.username} onChange={handleInputChange} />

        {/* Profile Picture */}
        <label htmlFor="profilePicture">{t.profilePicture}</label>
        <input type="file" accept="image/png, image/jpeg" onChange={handleProfilePictureChange} />

        {/* Password reset */}
        <label htmlFor="password">{t.newPassword}</label>
        <div className="password-field">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder={t.newPassword}
            onChange={handlePasswordChange}
            value={newPassword}
          />
          <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
          </span>
        </div>

        <button type="submit" className='button-submit'>{t.changeDataButtonLabel}</button>
      </form>
    </div>
  );
};

export default Settings;
