import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import LanguageSwitcher from './LanguageSwitcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faQrcode, faShieldAlt, faFileAlt, faEnvelope, faDownload, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import '../../styles/global.css';

const Footer = () => {
    const currentYear = new Date().getFullYear(); // Define currentYear
    const { language, translations } = useContext(LanguageContext);
    const t = translations[language].footer;
    const navigate = useNavigate();
    const handleCheckClick = () => {
        navigate('/check-qr-code');
      };

    const handleMobileAppButtonClick = () => {
        navigate('/mobile-app');
      };

      const handleFeedbackButtonClick = (url) => {
        window.open(url, "_blank", "noreferrer");
      };
    
  return (
    <footer>
      <nav className="navbar flex">
      <Link to="/data-protection" className="footer-link"><FontAwesomeIcon icon={faShieldAlt} /> {t.dataProtection}</Link>
      <Link to="/imprint" className="footer-link"><FontAwesomeIcon icon={faFileAlt} /> {t.imprint}</Link>
      <Link to="/contact" className="footer-link"><FontAwesomeIcon icon={faEnvelope} /> {t.contactUs}</Link>
      {/*<Link to="/check-qr-code" className="footer-link"> <FontAwesomeIcon icon={faQrcode} /> {t.checkNowQRCode}</Link>
      <Link to="/mobile-app" className="footer-link"><FontAwesomeIcon icon={faDownload} /> {t.mobileAppButton}</Link>*/}
      <Link to="https://forms.gle/YuTvvQ1BwZCPrGjr9" target="_blank" className="footer-link"><FontAwesomeIcon icon={faCommentDots} /> {t.feedbackAppButton}</Link>

      {/* <button type="button" className="button-check-footer" onClick={handleCheckClick}>
          {t.checkNowQRCode}
        </button>
      <button type="button" className="button-mobile-app" onClick={handleMobileAppButtonClick}>
        {t.mobileAppButton}
      </button>
     <button type="button" className="button-feedback" onClick= {() => handleFeedbackButtonClick("https://forms.gle/YuTvvQ1BwZCPrGjr9")}>
        {t.feedbackAppButton}
  </button> */}
      
      <p className="copyright">
        &copy; {currentYear} <a href="https://trustecvalley.com" target="_blank" className="footer-link">Trustecvalley</a>
      </p>
      
      {/* Other header content */}

      </nav>
    </footer>
  );
};

export default Footer;