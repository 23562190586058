import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const auth = getAuth();

  // Admin UID
  const adminUID = "2Z1QlUM5fWYAXINrwPwcfMk62vt1"; // Replace with the actual admin UID

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user || (user.uid !== adminUID && user.admin == true)) {
        navigate('/'); // Redirect to home if not the admin
      } else {
        // Fetch users from the backend and set them in state
        // setUsers(fetchedUsers);
      }
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, [navigate]);

  const handleEditUser = (userId) => {
    // Implement user edit functionality
  };

  return (
    <div className="user-list">
      <h2>User List</h2>
      {users.map(user => (
        <div key={user.id} className="user-item">
          <span>{user.name}</span>
          {/* Add more user details */}
          <button onClick={() => handleEditUser(user.id)}>Edit</button>
        </div>
      ))}
    </div>
  );
};

export default UserList;
