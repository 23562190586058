import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const QRCodeList = () => {
  const [qrCodes, setQRCodes] = useState([]);
  const navigate = useNavigate();
  const auth = getAuth();

  // Admin UID
  const adminUID = "2Z1QlUM5fWYAXINrwPwcfMk62vt1"; // Replace with the actual admin UID

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user || (user.uid !== adminUID && user.admin == true)) {
        navigate('/'); // Redirect to home if not the admin
      } else {
        // Fetch QR codes from the backend and set them in state
        // setQRCodes(fetchedQRCodes);
      }
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, [navigate]);

  const handleViewDetails = (qrCodeId) => {
    // Implement QR code detail view functionality
  };

  return (
    <div className="qr-code-list">
      <h2>QR Code List</h2>
      {qrCodes.map(qrCode => (
        <div key={qrCode.id} className="qr-code-item">
          <span>{qrCode.code}</span>
          <button onClick={() => handleViewDetails(qrCode.id)}>View Details</button>
        </div>
      ))}
    </div>
  );
};

export default QRCodeList;
