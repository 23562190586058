import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import '../../../styles/global.css';
import Spaceship from '../../../assets/images/Spaceship.png';

const NotFoundPage = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].notFound;

  return (
    <div className="not-found-page">
      <h1>{t.title}</h1>
      <p>{t.message}</p>
      <img src={Spaceship} alt="Spaceship" className="spaceship" />

    </div>
  );
};

export default NotFoundPage;
