import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import '../../../styles/global.css';

const ContactPage = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].contact;
  return (
    <div className="white-page-content">
    <div className="contact-page">
      <h1>{t.contactTitle}</h1>
      <p>{t.contactDescription}</p>
      
      <div className="contact-info">
        <h2>{t.customerService}</h2>
        <p><strong>{t.name}:</strong> {t.csName}</p>
        <p><strong>{t.phone}:</strong> {t.csPhone}</p>
        <p><strong>{t.email}:</strong> {t.csEmail}</p>

        <h2>{t.technicalSupport}</h2>
        <p><strong>{t.name}:</strong> {t.techName}</p>
        <p><strong>{t.phone}:</strong> {t.techPhone}</p>
        <p><strong>{t.hours}:</strong> {t.techHours}</p>
      </div>
    </div>
    </div>
  );
};

export default ContactPage;
