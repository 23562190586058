import React, { useState, useEffect, useContext } from 'react';
import { getAuth, sendPasswordResetEmail, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import '../../styles/global.css';

const ForgotPassword = () => {
    const { language, translations } = useContext(LanguageContext);
    const t = translations[language].login;

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate('/dashboard'); // Redirect to dashboard if logged in
            }
        });
        return unsubscribe;
    }, [navigate, auth]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Check your email to reset your password.');
        } catch (error) {
            setError('Failed to send password reset email.');
            console.error(error);
        }
    };

    return (
        <div className='white-page-content'>
        <div className="forgot-password-container">
            <h1>{t.resetPassword}</h1>
            {message && <p className="message">{message}</p>}
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder={t.enterYourEmail} 
                    required 
                />
                <button type="submit" className='button-submit'>{t.resetPassword}</button>
            </form>
        </div>
        </div>
    );
};

export default ForgotPassword;
