import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import 'react-phone-number-input/style.css';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import db, { auth } from '../../backend/firebase/firebaseConfig';
import { collection, addDoc, setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import '../../styles/global.css';
import { LanguageContext } from '../../contexts/LanguageContext';

const RegistrationForm = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].registration;
  const navigate = useNavigate();

  const generatePinCode = () => {
    const pinLength = 4; // For a 4-digit PIN
    let pin = '';
    for (let i = 0; i < pinLength; i++) {
      pin += Math.floor(Math.random() * 10).toString();
    }
    return pin;
  };

  const [formData, setFormData] = useState({
    gender: '',
    firstName: '',
    lastName: '',
    email: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    state: '',
    country: '',
    dateOfBirth: '',
    phoneNumber: '',
    username: '',
    password: '',
    confirmPassword: '',
    disabled: false,
    acceptGTC: true,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const [passwordError, setPasswordError] = useState(false); // New state for password error

  const [ageError, setAgeError] = useState(false); // New state for age

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validateForm = () => {
    console.log('Validating form data:', formData);
    let newErrors = {};
    // Add validation logic here
    if (!formData.gender) newErrors.gender = true;
    if (!formData.firstName) newErrors.firstName = true;
    if (!formData.lastName) newErrors.lastName = true;
    if (!formData.email) newErrors.email = true;
    if (!formData.street) newErrors.street = true;
    if (!formData.houseNumber) newErrors.houseNumber = true;
    if (!formData.postalCode) newErrors.postalCode = true;
    if (!formData.city) newErrors.city = true;
    if (!formData.state) newErrors.state = true;
    if (!formData.country) newErrors.country = true;
    if (!formData.dateOfBirth) newErrors.dateOfBirth = true;
    if (!formData.phoneNumber) newErrors.phoneNumber = true;
    if (!formData.username) newErrors.username = true;
    if (!formData.password) newErrors.password = true;
    if (!formData.confirmPassword) newErrors.confirmPassword = true;
    if (formData.password !== formData.confirmPassword) {
      newErrors.password = true;
      newErrors.confirmPassword = true;
    }
    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      newErrors.passwordMismatch = true;
      setPasswordError(true); // Set password error state to true
    } else {
      setPasswordError(false); // Set password error state to false
    }

      // Check if user is at least 16 years old
    const birthDate = new Date(formData.dateOfBirth);
    const currentDate = new Date();
    const minAgeDate = new Date(currentDate.getFullYear() - 16, currentDate.getMonth(), currentDate.getDate());

    if (birthDate > minAgeDate) {
      newErrors.ageError = true;
      setAgeError(true); // Set age error state to true
    } else {
      setAgeError(false); // Set age error state to false
    }
    
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting form with data:', formData);
    const formErrors = validateForm();
    setErrors(formErrors);
    console.log('Form validation errors:', formErrors);

  
    if (Object.keys(formErrors).length === 0) {
      try {
        // Create user with email and password
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );
        // User created successfully
        const user = userCredential.user;
        console.log("User created with UID:", user.uid);

        // Store additional user data in Firestore
        const userRef = doc(db, "users", user.uid); // Reference to 'users' collection
        await setDoc(userRef, {
          uid: user.uid, // Unique ID of the user
          creationTimestamp: serverTimestamp(), // Add server timestamp here
          customerServicePIN: generatePinCode() ,
          admin: false, // Creating by default a normal user
          identityVerified: false,
          gender: formData.gender,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          street: formData.street,
          houseNumber: formData.houseNumber,
          postalCode: formData.postalCode,
          city: formData.city,
          state: formData.state,
          country: formData.country,
          dateOfBirth: formData.dateOfBirth,
          phoneNumber: formData.phoneNumber,
          username: formData.username,
          disabled: false,
          acceptGTC: true,
          userWarnings: [], // Initialize userWarnings as an empty array
          // Do not store password or confirmPassword
        });
        // Additional logic to save other user details to Firestore or Realtime Database (if needed)
        console.log('User data stored in Firestore.');
        navigate('/account-verification');
        // Redirect or inform the user of successful registration
      } catch (error) {
        // Handle errors here, such as email already in use
        console.error("Error creating user:", error.message);
        if (error.code === 'auth/email-already-in-use') {
          setErrors({ ...errors, registrationError: 'This email is already in use. Please use a different email.' });
        } else if (error.code === 'auth/weak-password') {
          setErrors({ ...errors, registrationError: 'The password is too weak. Please use a stronger password.' });
        } else {
          setErrors({ ...errors, registrationError: error.message });
        }
      }
    }
  };

  return (
    <form className="registration-form" onSubmit={handleSubmit}>
 <label htmlFor="gender">{t.gender}</label>
      <select 
        name="gender" 
        id="gender" 
        value={formData.gender} 
        onChange={handleInputChange} 
        className={errors.gender ? 'error' : ''}
        required
      >
        <option value="">{t.selectGender}</option>
        <option value="female">{t.female}</option>
        <option value="male">{t.male}</option>
        <option value="no-entry">{t.noEntry}</option>
  </select> 

      <label htmlFor="firstName">{t.firstName}</label>
      <input 
      type="text" 
      name="firstName"
      id="firstName" 
      placeholder={t.firstName} 
      onChange={handleInputChange} 
      className={errors.firstName ? 'error' : ''}
      required
      />

      <label htmlFor="lastName">{t.lastName}</label>
      <input 
      type="text" 
      name="lastName"
      id="lastName" 
      placeholder={t.lastName} 
      onChange={handleInputChange} 
      className={errors.lastName ? 'error' : ''}
      required
      />

      <label htmlFor="email">{t.email}</label>
      <input 
      type="email" 
      name="email"
      id="email" 
      placeholder={t.email} 
      onChange={handleInputChange} 
      className={errors.email ? 'error' : ''}
      required
      />

      <label htmlFor="street">{t.street}</label>
      <input 
      type="text" 
      name="street" 
      id="street" 
      placeholder={t.street} 
      onChange={handleInputChange} 
      className={errors.street ? 'error' : ''}
      required
      />

      <label htmlFor="houseNumber">{t.houseNumber}</label>
      <input 
      type="text" 
      name="houseNumber" 
      id="houseNumber" 
      placeholder={t.houseNumber} 
      onChange={handleInputChange} 
      className={errors.houseNumber ? 'error' : ''}
      required
      />

      <label htmlFor="postalCode">{t.postalCode}</label>
      <input 
      type="text" 
      name="postalCode" 
      id="postalCode" 
      placeholder={t.postalCode} 
      onChange={handleInputChange} 
      className={errors.postalCode ? 'error' : ''}
      required
      />

      <label htmlFor="city">{t.city}</label>
      <input 
      type="text" 
      name="city" 
      id="city" 
      placeholder={t.city} 
      onChange={handleInputChange} 
      className={errors.city ? 'error' : ''}
      required
      />

      <label htmlFor="state">{t.state}</label>
      <input 
      type="text" 
      name="state" 
      id="state" 
      placeholder={t.state} 
      onChange={handleInputChange} 
      className={errors.state ? 'error' : ''}
      required
      />

      <label htmlFor="country">{t.country}</label>
      <input 
      type="text" 
      name="country" 
      id="country" 
      placeholder={t.country} 
      onChange={handleInputChange} 
      className={errors.country ? 'error' : ''}
      required
      />

      <label htmlFor="dateOfBirth">{t.dateOfBirth}</label>
      <input 
      type="date" 
      name="dateOfBirth" 
      id="dateOfBirth" 
      placeholder={t.dateOfBirth} 
      onChange={handleInputChange} 
      className={errors.dateOfBirth || ageError ? 'error' : ''}
      required
      />
      {ageError && <p className="error-message">{t.ageRequirementError}</p>}

      <label htmlFor="phoneNumber">{t.phoneNumber}</label>
      <input 
      type="tel" 
      name="phoneNumber" 
      id="phoneNumber" 
      placeholder={t.phoneNumber} 
      onChange={handleInputChange} 
      className={errors.phoneNumber ? 'error' : ''}
      required
      />

      <label htmlFor="username">{t.username}</label>
      <input 
      type="text" 
      name="username" 
      id="username" 
      placeholder={t.username} 
      onChange={handleInputChange} 
      className={errors.username ? 'error' : ''}
      required
      />

      <label htmlFor="password">{t.password}</label>
      <div className="password-field">
        <input
          type={showPassword ? 'text' : 'password'}
          name="password"
          id="password"
          placeholder={t.password}
          onChange={handleInputChange}
          className={passwordError ? 'error' : ''}
          required
        />
        <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
        </span>
      </div>
      <label htmlFor="confirmPassword">{t.confirmPassword}</label>
      <div className="password-field">
        <input
          type={showPassword ? 'text' : 'password'}
          name="confirmPassword"
          id="confirmPassword"
          placeholder={t.confirmPassword}
          onChange={handleInputChange}
          className={passwordError ? 'error' : ''}
          required
        />
        <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
        </span>
      </div>
      {passwordError && <p className="error-message">{t.passwordMismatchError}</p>}

      <div className="checkbox-container">
        <input
            type="checkbox"
            name="acceptGTC"
            id="acceptGTC"
            onChange={handleInputChange}
            required
        />
        <label htmlFor="acceptGTC">
             <a href="/terms-and-conditions" target="_blank">{t.acceptGTC}</a>
        </label>
      </div>

      <button type="submit" className='button-submit'>{t.registerButton}</button>
      {errors.registrationError && <p className="error-message">{errors.registrationError}</p>}

    </form>
  );
};

export default RegistrationForm;
