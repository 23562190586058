import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';

import '../../../styles/global.css'
import IntroVideo from '../../../videos/LogiHash_Intro_Video.mp4'
const IntroVideoPage = () => {
    const { language, translations } = useContext(LanguageContext);
    const t = translations[language].tutorials;
    return (
        <div className="white-page-content">
            <h1>{t.introVideo}</h1>
            <video controls width="800" height="450">
                <source src= {IntroVideo} />
            {t.browserNotSupportVideoTag}
            </video>
        </div>
    );
};

export default IntroVideoPage;