import React, { useContext, useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCamera, faEnvelope, faBox, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import db , {auth} from '../../backend/firebase/firebaseConfig';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../../styles/global.css';
import VerifiedIcon from '../../assets/images/VerifiedIcon.png';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useLocation, useNavigate } from 'react-router-dom';
import NavigationSideBar from '../dashboard/NavigationSideBar';
import QrScanner from 'qr-scanner';
import QrFrame from '../../assets/images/qr-frame.svg';

const CheckQRCode = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].checkQRCode;
  const [qrCodeValue, setQRCodeValue] = useState('');
  const [qrCodeDetails, setQRCodeDetails] = useState(null);
  const [scannerActive, setScannerActive] = useState(false);

  const [senderLogo, setSenderLogo] = useState('');
  const [senderIdentityColor, setSenderIdentityColor] = useState('');
  const [checkColor, setCheckColor] = useState('green');
  const [warningColor, setWarningColor] = useState('red');

  const [hasWarnings, setHasWarnings] = useState(false);

  const [showReportForm, setShowReportForm] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [reportReasons, setReportReasons] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const scanner = useRef(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoggedIn(!!user);
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  const handleInputChange = (e) => {
    setQRCodeValue(e.target.value);
  };

  // color change of the check symbol
  useEffect(() => {
    if (qrCodeDetails && qrCodeDetails.senderIdentityColor) {
      const intervalId = setInterval(() => {
        setCheckColor((prevColor) => prevColor === 'green' ? qrCodeDetails.senderIdentityColor : 'green');
      setWarningColor((prevColor) => prevColor === 'red' ? 'blue' : 'red');
    }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [qrCodeDetails]);

  const searchQRCode = async (qrCodeId) => {
    let docRef = doc(db, 'activeQRCodes', qrCodeId);
    let docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      const data = docSnap.data();
      setQRCodeDetails(data);
      if (data.senderLogo) {
        setSenderLogo(data.senderLogo);
      }
      if (data.senderIdentityColor) {
        setSenderIdentityColor(data.senderIdentityColor);
      }

      // Increment the numberOfScans field by 1
      await updateDoc(docRef, {
        numberOfScans: (data.numberOfScans || 0) + 1 // Increment the number or set to 1 if it doesn't exist
      });

      const senderUID = data.senderUID;
      let collectionName = '';
      if (senderUID.startsWith('COMDEP')) {
        collectionName = 'companyDepartments';
      } else if (senderUID.startsWith('BRANCHDEP')) {
        collectionName = 'branchDepartments';
      } else if (senderUID.startsWith('BRANCH')) {
        collectionName = 'branches';
      } else if (senderUID.startsWith('COMP')) {
        collectionName = 'companies';
      } else {
        collectionName = 'users';
      }

      docRef = doc(db, collectionName, senderUID);
      docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const warningsField = collectionName === 'users' ? 'userWarnings' : `${collectionName.slice(0, -1)}Warnings`;
        setHasWarnings((docSnap.data()[warningsField] || []).length > 1);
      } else {
        setHasWarnings(false);
      }

    } else {
      // Search in inactiveQRCodes
      docRef = doc(db, 'inactiveQRCodes', qrCodeId);
      docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        // Confirm activation
        if (window.confirm(t.doYouWantToActivateThisQRCode)) {
          if (auth.currentUser) { // Check if user is logged in
            await updateDoc(docRef, { IDOfOwner: auth.currentUser.uid });
            navigate(`/activate-qr-code?q=${qrCodeId}`); // Pass qrCodeId to navigate
          } else {
            // Save redirection path in sessionStorage
            sessionStorage.setItem('redirectAfterLogin', `/activate-qr-code?q=${qrCodeId}`);
            // Redirect to login page
            navigate('/login');
          }
        }
      } else {
        alert(t.QRCodeDoesnotExist);
        setQRCodeDetails(null);
      }
    }
  };

  const handleReportClick = () => {
    setShowReportForm(true);
  };

  const handleCloseReportForm = () => {
    setShowReportForm(false);
  };

  const handleReportReasonChange = (e) => {
    setReportReason(e.target.value);
  };

  const handleReportSubmit = async (e) => {
    e.preventDefault();
  
    // Get the logged-in user's UID
    const auth = getAuth();
    const user = auth.currentUser;
    const reporterUID = user ? user.uid : 'Anonymous'; // Default to 'Anonymous' if not logged in
  
    const reportEntry = {
      reason: reportReason,
      timestamp: new Date().toISOString(),
      reporterUID: reporterUID,
      reportedqrCodeValue: qrCodeValue,
    };
  
    const { senderUID } = qrCodeDetails;
  
    let collectionName = '';
    if (senderUID.startsWith('COMDEP')) {
      collectionName = 'companyDepartments';
    } else if (senderUID.startsWith('BRANCHDEP')) {
      collectionName = 'branchDepartments';
    } else if (senderUID.startsWith('BRANCH')) {
      collectionName = 'branches';
    } else if (senderUID.startsWith('COMP')) {
      collectionName = 'companies';
    } else {
      collectionName = 'users';
    }
  
    const senderDocRef = doc(db, collectionName, senderUID);
    const senderDocSnap = await getDoc(senderDocRef);
  
    if (senderDocSnap.exists()) {
      const warningsField = collectionName === 'users' ? 'userWarnings' : `${collectionName.slice(0, -1)}Warnings`;
      const currentWarnings = senderDocSnap.data()[warningsField] || [];
      await updateDoc(senderDocRef, {
        [warningsField]: [...currentWarnings, reportEntry],
      });
  
      console.log('Report submitted to sender document:', reportEntry);
    } else {
      console.error('Sender document not found:', senderUID);
    }
  
    // Add report entry to the QR code document
    const qrCodeDocRef = doc(db, 'activeQRCodes', qrCodeValue);
    const qrCodeDocSnap = await getDoc(qrCodeDocRef);
  
    if (qrCodeDocSnap.exists()) {
      const qrCodeWarnings = qrCodeDocSnap.data().qrCodeWarnings || [];
      await updateDoc(qrCodeDocRef, {
        qrCodeWarnings: [...qrCodeWarnings, reportEntry],
      });
  
      console.log('Report submitted to QR code document:', reportEntry);
    } else {
      // Search in inactiveQRCodes if not found in activeQRCodes
      const inactiveQrCodeDocRef = doc(db, 'inactiveQRCodes', qrCodeValue);
      const inactiveQrCodeDocSnap = await getDoc(inactiveQrCodeDocRef);
  
      if (inactiveQrCodeDocSnap.exists()) {
        const qrCodeWarnings = inactiveQrCodeDocSnap.data().qrCodeWarnings || [];
        await updateDoc(inactiveQrCodeDocRef, {
          qrCodeWarnings: [...qrCodeWarnings, reportEntry],
        });
  
        console.log('Report submitted to inactive QR code document:', reportEntry);
      } else {
        console.error('QR code document not found in both active and inactive collections:', qrCodeValue);
      }
    }
  
    handleCloseReportForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let docRef = doc(db, 'activeQRCodes', qrCodeValue);
    let docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      setQRCodeDetails(data);
      if (data.senderLogo) {
        setSenderLogo(data.senderLogo);
      }
      if (data.senderIdentityColor) {
        setSenderIdentityColor(data.senderIdentityColor);
      }

      // Increment the numberOfScans field by 1
      await updateDoc(docRef, {
        numberOfScans: (data.numberOfScans || 0) + 1 // Increment the number or set to 1 if it doesn't exist
      });

    } else {
      // Search in inactiveQRCodes
      docRef = doc(db, 'inactiveQRCodes', qrCodeValue);
      docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Confirm activation
        if (window.confirm(t.doYouWantToActivateThisQRCode)) {
          if (auth.currentUser) { // Check if user is logged in
            await updateDoc(docRef, { IDOfOwner: auth.currentUser.uid });
            navigate(`/activate-qr-code?q=${qrCodeValue}`);
          } else {
            // Save redirection path in sessionStorage
            sessionStorage.setItem('redirectAfterLogin', `/activate-qr-code?q=${qrCodeValue}`);
            // Redirect to login page
            navigate('/login');
          }
        }
      } else {
        alert(t.QRCodeDoesnotExist);
        setQRCodeDetails(null);
      }
    }
  };


  const handleScanSuccess = (result) => {
    try {
      const url = new URL(result);
      const qrCodeValue = url.searchParams.get('q');
      if (qrCodeValue) {
        setQRCodeValue(qrCodeValue);
        searchQRCode(qrCodeValue);
        setScannerActive(false); // Close the scanner after a successful scan
      } else {
        alert(t.invalidQRCodeFormat);
      }
    } catch (error) {
      alert(t.invalidQRCodeFormat);
    }
  };

  const handleScanFail = (error) => {
    console.error(error);
  };

  const toggleScanner = () => {
    setScannerActive(!scannerActive);
  };

  useEffect(() => {
    if (scannerActive && videoEl.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl.current, result => handleScanSuccess(result.data), {
        onDecodeError: handleScanFail,
        preferredCamera: 'environment',
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl.current
      });
      scanner.current.start().catch(err => console.error(err));
    } else if (!scannerActive && scanner.current) {
      scanner.current.stop();
      scanner.current = null;
    }
  }, [scannerActive]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const qrCodeIdFromURL = query.get('q');
    if (qrCodeIdFromURL) {
      setQRCodeValue(qrCodeIdFromURL);
      searchQRCode(qrCodeIdFromURL);
    }
  }, [location]);
    
    

  return (
<div className="white-page-content">
    <div className="check-qr-code-page">
      <div>    { isLoggedIn && <NavigationSideBar/>}
      <h1>{t.title}</h1>
      <p>{t.description}</p>
      <form onSubmit={handleSubmit} className="qr-code-form">
        <div className="input-group">
          <input
            type="text"
            value={qrCodeValue}
            onChange={handleInputChange}
            placeholder={t.placeholder}
            required
          />
          <button type="submit" className="button-search">
            <FontAwesomeIcon icon={faSearch} />
          </button>
          <button type="button" className="button-camera" onClick={toggleScanner}>
              <FontAwesomeIcon icon={faCamera} />
            </button>
        </div>
      </form>
      {scannerActive && (
          <div className="qr-reader">
            <video ref={videoEl}></video>
            <div ref={qrBoxEl} className="qr-box">
              <img
                src={QrFrame}
                alt="Qr Frame"
                width={256}
                height={256}
                className="qr-frame"
              />
            </div>
          </div>
        )}
      {qrCodeDetails && (
          <div className="qr-code-details">

            <div className="verified-icon">
              {senderLogo ? (
                <>
                  <img src={senderLogo} alt="Sender Logo" />
                  <div className="check-symbol" style={{ color: hasWarnings ? warningColor : checkColor }}>
                    <FontAwesomeIcon icon={hasWarnings ? faExclamationTriangle : faCheckCircle} />
                  </div>
                </>
              ) : (
                  <div className="check-symbol" style={{ color: hasWarnings ? warningColor : checkColor }}>
                    <FontAwesomeIcon icon={hasWarnings ? faExclamationTriangle : faCheckCircle} />
                  </div>
              )}
            </div>

            <div className="details-grid" style={{ border: `4px dashed ${qrCodeDetails.senderIdentityColor}` }}>
              <div className="section-label">{t.productInformation}</div>
              <div className="details-item"><span>{t.qrCodeProduct}: </span> 
              {qrCodeDetails.qrCodeProduct === 'ENVELOPE' && (
                <>
                 {t.envelope}  <FontAwesomeIcon icon={faEnvelope} />
                </>
              )}
              {qrCodeDetails.qrCodeProduct === 'PARCEL' && (
                <>
                  {t.parcel}  <FontAwesomeIcon icon={faBox} />
                </>
              )}
              </div>
              <div className="details-item" ><span>{t.qrCodeProductSize}:</span> {qrCodeDetails.qrCodeProductSize}</div>

              <div className="section-label">{t.senderInformation}</div>
              <div className="details-item"><span>{t.senderName}:</span> {qrCodeDetails.senderName}</div>
              <div className="details-item"><span>{t.senderStreet}:</span> {qrCodeDetails.senderStreet}</div>
              <div className="details-item"><span>{t.senderHouseNumber}:</span> {qrCodeDetails.senderHouseNumber}</div>
              <div className="details-item"><span>{t.senderPostalCode}:</span> {qrCodeDetails.senderPostalCode}</div>
              <div className="details-item"><span>{t.senderCity}:</span> {qrCodeDetails.senderCity}</div>
              <div className="details-item"><span>{t.senderState}:</span> {qrCodeDetails.senderState}</div>
              <div className="details-item"><span>{t.senderCountry}:</span> {qrCodeDetails.senderCountry}</div>

              <div className="section-label">{t.recipientInformation}</div>
              <div className="details-item"><span>{t.recipientName}:</span> {qrCodeDetails.recipientName}</div>
              <div className="details-item"><span>{t.recipientStreet}:</span> {qrCodeDetails.recipientStreet}</div>
              <div className="details-item"><span>{t.recipientHouseNumber}:</span> {qrCodeDetails.recipientHouseNumber}</div>
              <div className="details-item"><span>{t.recipientPostalCode}:</span> {qrCodeDetails.recipientPostalCode}</div>
              <div className="details-item"><span>{t.recipientCity}:</span> {qrCodeDetails.recipientCity}</div>
              <div className="details-item"><span>{t.recipientState}:</span> {qrCodeDetails.recipientState}</div>
              <div className="details-item"><span>{t.recipientCountry}:</span> {qrCodeDetails.recipientCountry}</div>
              <div className="details-item"><span>{t.recipientPhoneNumber}:</span> {qrCodeDetails.recipientPhoneNumber}</div>
              <div className="details-item"><span>{t.recipientLogiHashID}:</span> {qrCodeDetails.recipientLogiHashID}</div>
            </div>

            <div>
              <button onClick={handleReportClick} className="button-report">
                <box-icon type='solid' color='white' name='report'></box-icon>
                <p>{t.reportAbuse}</p>
              </button>
            </div>
          </div>
        )}

        {showReportForm && (

        <form onSubmit={handleReportSubmit} className="popup-report">
          <h1>{t.reportAbuse}</h1>
          <div><button type="button" onClick={handleCloseReportForm} className="button-exit-popup-report">X</button></div>
          <label htmlFor="reportReason">{t.selectReason}: </label>
          <select 
            id="reportReason" 
            value={reportReason} 
            onChange={handleReportReasonChange} 
            className="report-select-popup-report"
            required
          >
            <option value="" disabled>{t.selectReason}</option>
            <option value="threateningMessage" >{t.threateningMessage}</option>
            <option value="fakeContent" >{t.fakeContent}</option>
            <option value="dangerousContent" >{t.dangerousContent}</option>
            <option value="identityTheft" >{t.identityTheft}</option>
          </select>
          <button type='submit' className='button-submit-popup-report'>{t.submit}</button>
        </form>
        )}

    <div className="mobile-app-note-container">
      <p className="mobile-app-note">{t.mobileAppNote}</p>
    </div>
    </div>
  </div>
  </div>
  );
};

export default CheckQRCode;
