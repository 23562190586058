import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../../styles/global.css';
import { LanguageContext } from '../../contexts/LanguageContext';
import db, { auth } from '../../backend/firebase/firebaseConfig';
import { doc, updateDoc, getDoc } from "firebase/firestore";

import { signInWithEmailAndPassword, signOut } from 'firebase/auth';

const LoginForm = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].login;
  

  // Define the state for form data and errors
  const [formData, setFormData] = useState({
    usernameEmail: '',
    password: '',
  });
  const [errors, setErrors] = useState({ loginError: '' });
  const [showPassword, setShowPassword] = useState(false);
  
  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const generatePinCode = () => {
    const pinLength = 4; // For a 4-digit PIN
    let pin = '';
    for (let i = 0; i < pinLength; i++) {
      pin += Math.floor(Math.random() * 10).toString();
    }
    return pin;
  };

  const navigate = useNavigate();

  // Add a submit handler and mark it as async
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Use await with signInWithEmailAndPassword
      const userCredential = await signInWithEmailAndPassword(auth, formData.usernameEmail, formData.password);
      const user = userCredential.user;


      // Check if the user's account is disabled
      const userRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userRef);
        if (docSnap.exists() && docSnap.data().disabled) {

          signOut(auth).then(() => {
            navigate('/contact');
          }).catch((error) => {
            console.error('Error signing out:', error);
          });

          alert(t.alertMessageAccountDisabled);
        }

      // Generate a new customer Service PIN code for the user
      const newPinCode = generatePinCode();

          // Update the user's document in Firestore
      await updateDoc(userRef, {
        customerServicePIN: newPinCode
      });

     // Redirect logic
     const redirectUrl = sessionStorage.getItem('redirectAfterLogin');
     if (redirectUrl) {
       navigate(redirectUrl);
       sessionStorage.removeItem('redirectAfterLogin'); // Clear the stored URL
     } else {
       navigate('/dashboard'); // Default redirection if no specific URL was stored
     }
 
    } catch (error) {
      // Handle errors here
      setErrors({ ...errors, loginError: error.message });
      // Update the state to show error message to the user
    }
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <label htmlFor="usernameEmail">{t.usernameEmail}</label>
      <input
        type="text" 
        name="usernameEmail" 
        placeholder={t.usernameEmail} 
        onChange={handleInputChange} 
        className={errors.usernameEmail ? 'error' : 'inputLogin'}
        required
      />
      <label htmlFor="password">{t.password}</label>
      <div className="password-field">
        <input
          type={showPassword ? 'text' : 'password'}
          name="password"
          id="password"
          placeholder={t.password}
          onChange={handleInputChange}
          className={errors.password ? 'error' : 'inputLogin'}
          required
        />
        <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
        </span>
      </div>

      <div className="checkbox-container">
        <input
          type="checkbox"
          name="stayLoggedIn"
          id="stayLoggedIn"
        />
        <label htmlFor="stayLoggedIn" className="checkbox-container">{t.stayLoggedIn}</label>
      </div>
      <Link to="/forgot-password" className="forgot-password-link"> {t.forgotPassword}</Link>
      <button type="submit" className="button-submit">{t.loginButton}</button>
      {errors.loginError && <p className="error-message">{errors.loginError}</p>}
      {/*<button type="button" className="social-login">{t.loginWithGoogle}</button>*/}
      {/* Add more social login buttons as needed */}
      <p>{t.newHere} <Link to="/register" color='black'> {t.registerNow}</Link></p>
      
    </form>
    
  );
};

export default LoginForm;
