import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import db, { auth } from '../../backend/firebase/firebaseConfig';
import { collection, addDoc, setDoc, query, where, getDocs, updateDoc, deleteDoc, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import VerifiedIcon from '../../assets/images/VerifiedIcon.png';
import '../../styles/global.css';

const ManageCompanies = () => {
    const { language, translations } = useContext(LanguageContext);
    const t = translations[language].dashboard;
    const [companies, setCompanies] = useState([]);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [newCompanyData, setNewCompanyData] = useState({
        companyName: '',
        companyEmail: '',
        street: '',
        houseNumber: '',
        postalCode: '',
        city: '',
        state: '',
        country: '',
        companyPhoneNumber: '',
        companyWebsite: '',
        companyOfficialRegistrationNumber: '',
        companyIdentityColor: '#30acfd',

        // Add other fields
    });

    const [newBranchData, setNewBranchData] = useState({
        branchName: '',
        branchEmail: '',
        street: '',
        houseNumber: '',
        postalCode: '',
        city: '',
        country: '',
        branchPhoneNumber: '',
    });
    const [newDepartmentData, setNewDepartmentData] = useState({
        departmentName: '',
        departmentEmail: '',
        street: '',
        houseNumber: '',
        postalCode: '',
        city: '',
        country: '',
        departmentPhoneNumber: '',
    });
    const [editingCompany, setEditingCompany] = useState(null);
    const [editingCompanyData, setEditingCompanyData] = useState({
        // Initialize with the same structure as newCompanyData
    });
    
    const [selectedCompanyId] = useState(null);

    const [file, setFile] = useState(null); // State to hold the file object
    const [logoFile, setLogoFile] = useState(null); // State to hold the logo file

    const [popupState, setPopupState] = useState({
        selectedCompanyId: null,
        showAddUserPopup: false,
        showAddAdminPopup: false,
        showAddBranchPopup: false,
        showAddDepartmentPopup: false,
        showRemoveDepartmentPopup: false,
        showRemoveAdminPopup: false,
        showRemoveBranchPopup: false,
        showRemoveUserPopup: false,
        newUserId: '',
        newAdminId: '',
        newBranchId: '',
        newDepartmentId: ''

    });

    useEffect(() => {
        const fetchCompanies = async () => {
            const user = auth.currentUser;
            if (user) {
                // Query for companies where the user is an admin
                const adminQuery = query(collection(db, "companies"), where("companyAdmins", "array-contains", user.uid));
                const adminSnapshot = await getDocs(adminQuery);
    
                // Query for companies where the user is a regular user
                const userQuery = query(collection(db, "companies"), where("companyUsers", "array-contains", user.uid));
                const userSnapshot = await getDocs(userQuery);
    
                // Combine the results from both queries
                const combinedCompanies = [...adminSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })), 
                                           ...userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))];
    
                // Remove duplicates if any (in case the user is both admin and regular user in the same company)
                const uniqueCompanies = Array.from(new Map(combinedCompanies.map(company => [company['id'], company])).values());
    
                setCompanies(uniqueCompanies);
            }
        };
        fetchCompanies();
    }, []);

    const startAddingCompany = async () => {
        setShowCreateForm(true);
        setPopupState({
            ...popupState,
            showAddUserPopup: false,
            showAddAdminPopup: false,
            showAddBranchPopup: false,
            showAddDepartmentPopup: false,
            showRemoveUserPopup: false,
            showRemoveAdminPopup: false,
            showRemoveBranchPopup: false,
            showRemoveDepartmentPopup: false
        });
        setEditingCompany(null);
    };


    // Function to handle file upload to Firebase Storage
    const uploadFile = async (file, companyName, companyUID, fileType) => {
        const timestamp = new Date();
        const date = timestamp.getDate();
        const month = timestamp.getMonth() + 1; // Month is zero-based, so add 1
        const year = timestamp.getFullYear();
        const hours = timestamp.getHours();
        const minutes = timestamp.getMinutes();
        const seconds = timestamp.getSeconds();
        
        // Format the timestamp
        const formattedTimestamp = `${year}-${month}-${date}_${hours}-${minutes}-${seconds}`;
        
        const fileName = `${companyName}_${companyUID}_${formattedTimestamp}_${fileType}`;
        
        const storage = getStorage();
        const fileRef = storageRef(storage, `companyDocuments/${companyUID}/${fileName}`);
        await uploadBytes(fileRef, file);
        return getDownloadURL(fileRef);
    };


    const handleCreateCompany = async (e) => {
        e.preventDefault();

        console.log("Submitting company data:", newCompanyData);
    
        try {
            // Generate a unique companyUID
            const companyUID = `COMP-${Date.now()}`;
            let fileURL = '';
            let logoURL = '';
            if (file) {
                fileURL = await uploadFile(file, newCompanyData.companyName, companyUID, 'registration_file'); // Upload file and get URL
            }
            if (logoFile) {
                logoURL = await uploadFile(logoFile, newCompanyData.companyName, companyUID, 'company_logo'); // Upload logo file and get URL
            }
    
            // Create a reference to a new document with the companyUID
            const companyRef = doc(db, "companies", companyUID);
    
            // Set the document data
            await setDoc(companyRef, {
                ...newCompanyData,
                companyOfficialRegistrationDocument: fileURL,
                companyLogo: logoURL,
                companyUID: companyUID,
                creationTimestamp: serverTimestamp(), // Add server timestamp here
                companyAdmins: [auth.currentUser.uid],
                companyUsers: [],
                branches: [],
                mainDepartments: [],
                companyWarnings: [],
            });
    
            console.log("Company added with ID:", companyUID);
            setCompanies([...companies, { id: companyUID, ...newCompanyData, registrationDocument: fileURL }]);
            setShowCreateForm(false);
        } catch (error) {
            console.error("Error adding company: ", error);
        }
        window.location.reload();
    };

    const handleExitCreatingCompany = async ()=> {
        setShowCreateForm(null);
    
    };

    // Update the file state when a file is selected
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleLogoChange = (e) => {
        setLogoFile(e.target.files[0]);
    };

    const handleLeaveUserCompany = async (companyId) => {
        if (window.confirm(t.leaveCompanyUserMessage)) {

            try {
                const companyRef = doc(db, "companies", companyId);
                const companyDoc = await getDoc(companyRef);
                if (companyDoc.exists()) {
                    const updatedUsers = companyDoc.data().companyUsers.filter(uid => uid !== auth.currentUser.uid);
                    await updateDoc(companyRef, { companyUsers: updatedUsers });
                    // Update local state or re-fetch companies
                }
            } catch (error) {
                console.error("Error leaving company: ", error);
            }
        }
        window.location.reload();
    };

    const handleDeleteCompany = async (companyId) => {
        if (window.confirm(t.leaveDeleteCompanyMessage)) {
            try {
                const companyRef = doc(db, "companies", companyId);
                const companyDoc = await getDoc(companyRef);
                if (companyDoc.exists()) {
                    const companyData = companyDoc.data();

                    // Check if branches and companyDepartments arrays are empty
                    if (companyData.branches.length > 0 || companyData.mainDepartments.length > 0) {
                        alert(t.alertDeleteAllBranchesAndDepartments);
                        return; // Exit the function if the condition is not met
                    }
    
                    // Add to deletedCompanies collection
                    await addDoc(collection(db, "deletedCompanies"), { ...companyData, deletedAt: new Date() });

                    // Delete from companies collection
                    await deleteDoc(companyRef);
    
                    // Update local state or re-fetch companies
                    setCompanies(companies.filter(company => company.id !== companyId));
                }
            } catch (error) {
                console.error("Error moving company to deletedCompanies: ", error);
            }
        }
        window.location.reload();

    };

    const handleLeaveAdminCompany = async (companyId) => {
        if (window.confirm(t.leaveCompanyAdminMessage)) {
            try {
                const companyRef = doc(db, "companies", companyId);
                const companyDoc = await getDoc(companyRef);
    
                if (companyDoc.exists()) {
                    const currentAdmins = companyDoc.data().companyAdmins;
                    const updatedAdmins = currentAdmins.filter(uid => uid !== auth.currentUser.uid);
    
                    if (updatedAdmins.length === 0) {
                        // If the current user is the only admin
                        alert(t.alertYouAreOnlyAdminInCompany);
                    } else {
                        // If there are other admins, proceed with leaving
                        await updateDoc(companyRef, { companyAdmins: updatedAdmins });
                        // Update local state or re-fetch companies
                    }
                }
            } catch (error) {
                console.error("Error leaving company: ", error);
            }
        }
        window.location.reload();
    };


    const startEditCompany = async (companyId) => {
        try {
            const companyRef = doc(db, "companies", companyId);
            const companyDoc = await getDoc(companyRef);

            setPopupState({
                ...popupState,
                showAddUserPopup: false,
                showAddAdminPopup: false,
                showAddBranchPopup: false,
                showAddDepartmentPopup: false,
                showRemoveUserPopup: false,
                showRemoveAdminPopup: false,
                showRemoveBranchPopup: false,
                showRemoveDepartmentPopup: false
            });
            setShowCreateForm(null);

            if (companyDoc.exists()) {
                setEditingCompany(companyId); // Set the ID of the company being edited
                setEditingCompanyData(companyDoc.data());
            }
        } catch (error) {
            console.error("Error fetching company data for editing: ", error);
        }
    };
    
    const handleEditCompany = async (e, companyId) => {
        e.preventDefault();
        try {
            const companyRef = doc(db, "companies", companyId);
            let logoURL = editingCompanyData.companyLogo;

            if (logoFile) {
                logoURL = await uploadFile(logoFile, editingCompanyData.companyName, companyId, 'company_logo');
            }

            await updateDoc(companyRef, { ...editingCompanyData, companyLogo: logoURL });
            setEditingCompany(null); // Reset editing state
        } catch (error) {
            console.error("Error updating company: ", error);
        }
        window.location.reload();
    };

    const handleExitEditingCompany = async ()=> {
        setEditingCompany(null);
    
    };


    const showAddUser = (companyId) => {
        setPopupState({
            ...popupState,
            selectedCompanyId: companyId,
            showAddUserPopup: true,
            showAddAdminPopup: false,
            showAddBranchPopup: false,
            showAddDepartmentPopup: false,
            showRemoveUserPopup: false,
            showRemoveAdminPopup: false,
            showRemoveBranchPopup: false,
            showRemoveDepartmentPopup: false
        });
        setEditingCompany(null);
        setShowCreateForm(null);
    };

    const handleAddUserToCompany = async (e,selectedCompanyId) => {
        e.preventDefault();
        try {
            const companyRef = doc(db, "companies", popupState.selectedCompanyId);
            const companyDoc = await getDoc(companyRef);
    
            if (companyDoc.exists()) {
                const currentUsers = companyDoc.data().companyUsers;
                const newUserId = popupState.newUserId;
    
                // Check if the new user ID is already in the array
                if (!currentUsers.includes(newUserId)) {
                    const updatedUsers = [...currentUsers, newUserId];
                    await updateDoc(companyRef, { companyUsers: updatedUsers });
    
                    // Update local state
                    const updatedCompanies = companies.map(company => {
                        if (company.id === popupState.selectedCompanyId) {
                            return { ...company, companyUsers: updatedUsers };
                        }
                        return company;
                    });
                    setCompanies(updatedCompanies);
                } else {
                    alert(t.alertUserIDAlreadyExists);
                    console.log("User ID already exists in the companyUsers array.");
                }
            }
        } catch (error) {
            console.error("Error adding user to company: ", error);
        }
        setPopupState({ ...popupState, showAddUserPopup: false, newUserId: '' });
        window.location.reload();
    };


    const showAddAdmin = (companyId) => {
        setPopupState({
            ...popupState,
            selectedCompanyId: companyId,
            showAddAdminPopup: true,
            showAddUserPopup: false,
            showAddBranchPopup: false,
            showAddDepartmentPopup: false,
            showRemoveUserPopup: false,
            showRemoveAdminPopup: false,
            showRemoveBranchPopup: false,
            showRemoveDepartmentPopup: false
        });
        setEditingCompany(null);
        setShowCreateForm(null);
    };
    const handleAddAdminToCompany = async (e,selectedCompanyId) => {
        e.preventDefault();
        try {
            const companyRef = doc(db, "companies", popupState.selectedCompanyId);
            const companyDoc = await getDoc(companyRef);
    
            if (companyDoc.exists()) {
                const currentAdmins = companyDoc.data().companyAdmins;
                const newAdminId = popupState.newAdminId;
    
                // Check if the new admin ID is already in the array
                if (!currentAdmins.includes(newAdminId)) {
                    const updatedAdmins = [...currentAdmins, newAdminId];
                    await updateDoc(companyRef, { companyAdmins: updatedAdmins });
    
                    // Update local state
                    const updatedCompanies = companies.map(company => {
                        if (company.id === popupState.selectedCompanyId) {
                            return { ...company, companyAdmins: updatedAdmins };
                        }
                        return company;
                    });
                    setCompanies(updatedCompanies);
                } else {
                    alert(t.alertAdminIDAlreadyExists);
                    console.log("Admin ID already exists in the companyAdmins array.");
                }
            }
        } catch (error) {
            console.error("Error adding admin to company: ", error);
        }
        setPopupState({ ...popupState, showAddAdminPopup: false, newAdminId: '' });
        window.location.reload();
    };

    const showAddBranch = (companyId) => {
        setPopupState({
            ...popupState,
            selectedCompanyId: companyId,
            showAddAdminPopup: false,
            showAddUserPopup: false,
            showAddBranchPopup: true,
            showAddDepartmentPopup: false,
            showRemoveUserPopup: false,
            showRemoveAdminPopup: false,
            showRemoveBranchPopup: false,
            showRemoveDepartmentPopup: false
        });
        setEditingCompany(null);
        setShowCreateForm(null);

        setNewBranchData({
            branchName: '',
            branchEmail: '',
            street: '',
            houseNumber: '',
            postalCode: '',
            city: '',
            country: '',
            branchPhoneNumber: ''
        });
    };

    const handleAddBranchToCompany = async (e,selectedCompanyId) => {
        e.preventDefault();
        try {
            const branchUID = `BRANCH-${Date.now()}`;
            const newBranch = {
                ...newBranchData,
                branchUID,
                branchCompany: popupState.selectedCompanyId,
                creationTimestamp: serverTimestamp(),
                branchAdmins: [auth.currentUser.uid],
                branchUsers: [],
                branchDepartments: [],
                branchWarnings: [],
            };
    
            // Add new branch to the branches collection
            await setDoc(doc(db, "branches", branchUID), newBranch);
    
            // Update the company's branches array
            const companyRef = doc(db, "companies", popupState.selectedCompanyId);
            const companyDoc = await getDoc(companyRef);
            if (companyDoc.exists()) {
                const updatedBranches = [...companyDoc.data().branches, branchUID];
                await updateDoc(companyRef, { branches: updatedBranches });
    
                // Update local state
                const updatedCompanies = companies.map(company => {
                    if (company.id === popupState.selectedCompanyId) {
                        return { ...company, branches: updatedBranches };
                    }
                    return company;
                });
                setCompanies(updatedCompanies);
            }
        } catch (error) {
            console.error("Error adding branch to company: ", error);
        }
        setPopupState({ ...popupState, showAddBranchPopup: false });
        window.location.reload();
    };

    const showAddDepartment = (companyId) => {
        setPopupState({
            ...popupState,
            selectedCompanyId: companyId,
            showAddAdminPopup: false,
            showAddUserPopup: false,
            showAddBranchPopup: false,
            showAddDepartmentPopup: true,
            showRemoveUserPopup: false,
            showRemoveAdminPopup: false,
            showRemoveBranchPopup: false,
            showRemoveDepartmentPopup: false
        });
        setEditingCompany(null);
        setShowCreateForm(null);

        setNewDepartmentData({
            departmentName: '',
            departmentEmail: '',
            street: '',
            houseNumber: '',
            postalCode: '',
            city: '',
            country: '',
            departmentPhoneNumber: ''
        });
    };
    
    const handleAddDepartmentToCompany = async (e,selectedCompanyId) => {
        e.preventDefault();
        try {
            const departmentUID = `COMDEP-${Date.now()}`;
            const newDepartment = {
                ...newDepartmentData,
                departmentUID,
                departmentCompany: popupState.selectedCompanyId,
                creationTimestamp: serverTimestamp(),
                departmentAdmins: [auth.currentUser.uid],
                departmentUsers: [],
                departmentWarnings: [],
            };
    
            // Add new main department to the mainDepartments collection
            await setDoc(doc(db, "companyDepartments", departmentUID), newDepartment);
    
            // Update the company's mainDepartments array
            const companyRef = doc(db, "companies", popupState.selectedCompanyId);
            const companyDoc = await getDoc(companyRef);

            if (companyDoc.exists()) {
                const updatedDepartments = [...companyDoc.data().mainDepartments, departmentUID];
                await updateDoc(companyRef, { mainDepartments: updatedDepartments });
    
                // Update local state
                const updatedCompanies = companies.map(company => {
                    if (company.id === popupState.selectedCompanyId) {
                        return { ...company, mainDepartments: updatedDepartments };
                    }
                    return company;
                });
                setCompanies(updatedCompanies);
            }
        } catch (error) {
            console.error("Error adding main department to company: ", error);
        }
        setPopupState({ ...popupState, showAddDepartmentPopup: false });
        
        window.location.reload();
    };
    
    const ArrayDisplay = ({ arrayData = [] }) => (
        <div>
          <ul>
            {Array.isArray(arrayData) && arrayData.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      );

    const showRemoveUser = (companyId) => {
        setPopupState({
            ...popupState,
            selectedCompanyId: companyId,
            showRemoveUserPopup: true,
            showRemoveAdminPopup: false,
            showRemoveBranchPopup: false,
            showRemoveDepartmentPopup: false,
            showAddAdminPopup: false,
            showAddUserPopup: false,
            showAddBranchPopup: false,
            showAddDepartmentPopup: false
        });
        setEditingCompany(null);
        setShowCreateForm(null);
    };
    
    const handleRemoveUserFromCompany = async (e,selectedCompanyId) => {
        e.preventDefault();
        try {
            const companyRef = doc(db, "companies", popupState.selectedCompanyId);
            const companyDoc = await getDoc(companyRef);
    
            if (companyDoc.exists()) {
                const currentUsers = companyDoc.data().companyUsers;
                const userToRemove = popupState.userToRemoveId;
    
                // Check if the user to remove exists in the array
                if (currentUsers.includes(userToRemove)) {
                    const updatedUsers = currentUsers.filter(userId => userId !== userToRemove);
                    await updateDoc(companyRef, { companyUsers: updatedUsers });
    
                    // Update local state
                    const updatedCompanies = companies.map(company => {
                        if (company.id === popupState.selectedCompanyId) {
                            return { ...company, companyUsers: updatedUsers };
                        }
                        return company;
                    });
                    setCompanies(updatedCompanies);
                } else {
                    alert(t.alertUserNotInCompany);
                }
            }
        } catch (error) {
            console.error("Error removing user from company: ", error);
        }
        setPopupState({ ...popupState, showRemoveUserPopup: false, userToRemoveId: '' });
        window.location.reload();
    };


    const showRemoveAdmin = (companyId) => {
        setPopupState({
            ...popupState,
            selectedCompanyId: companyId,
            showRemoveUserPopup: false,
            showRemoveAdminPopup: true,
            showRemoveBranchPopup: false,
            showRemoveDepartmentPopup: false,
            showAddAdminPopup: false,
            showAddUserPopup: false,
            showAddBranchPopup: false,
            showAddDepartmentPopup: false
        });
        setEditingCompany(null);
        setShowCreateForm(null);
    };


    const handleRemoveAdminFromCompany = async (e,selectedCompanyId) => {
        e.preventDefault();
        try {
            const companyRef = doc(db, "companies", popupState.selectedCompanyId);
            const companyDoc = await getDoc(companyRef);
    
            if (companyDoc.exists()) {
                const currentAdmins = companyDoc.data().companyAdmins;
                const adminToRemove = popupState.adminToRemoveId;
                const currentUserUid = auth.currentUser.uid; // Get the current user's UID
    
                // Check if the admin to remove exists in the array
                if (currentAdmins.includes(adminToRemove)) {
                    // Check if the admin to remove is the current user
                    if (adminToRemove === currentUserUid) {
                        // Show confirmation dialog
                        if (window.confirm(t.leaveCompanyAdminMessage)) {
                            // Proceed with removing the admin
                            await removeAdmin(currentAdmins, companyRef, adminToRemove);
                        }
                    } else {
                        // Proceed with removing the admin
                        await removeAdmin(currentAdmins, companyRef, adminToRemove);
                    }
                } else {
                    alert(t.alertUserNotAdmin);
                }
            }
        } catch (error) {
            console.error("Error removing admin from company: ", error);
        }
        setPopupState({ ...popupState, showRemoveAdminPopup: false, adminToRemoveId: '' });
        window.location.reload();
    };
    
    // Helper function to remove admin
    const removeAdmin = async (currentAdmins, companyRef, adminToRemove) => {
        if (currentAdmins.length > 1) {
            const updatedAdmins = currentAdmins.filter(adminId => adminId !== adminToRemove);
            await updateDoc(companyRef, { companyAdmins: updatedAdmins });
    
            // Update local state
            const updatedCompanies = companies.map(company => {
                if (company.id === popupState.selectedCompanyId) {
                    return { ...company, companyAdmins: updatedAdmins };
                }
                return company;
            });
            setCompanies(updatedCompanies);
        } else {
            alert(t.alertYouAreOnlyAdminInCompany);
        }
    };
    

    const showRemoveBranch = (companyId) => {
        setPopupState({
            ...popupState,
            selectedCompanyId: companyId,
            showRemoveUserPopup: false,
            showRemoveAdminPopup: false,
            showRemoveBranchPopup: true,
            showRemoveDepartmentPopup: false,
            showAddAdminPopup: false,
            showAddUserPopup: false,
            showAddBranchPopup: false,
            showAddDepartmentPopup: false
        });
        setEditingCompany(null);
        setShowCreateForm(null);
    };
    
    const handleRemoveBranchFromCompany = async (e,selectedCompanyId) => {
        e.preventDefault();
        if (!window.confirm(t.deleteBranchmessage)) {
            return;
        }
    
        try {
            const companyRef = doc(db, "companies", popupState.selectedCompanyId);
            const companyDoc = await getDoc(companyRef);
    
            if (companyDoc.exists()) {
                const currentBranches = companyDoc.data().branches;
                const branchToRemove = popupState.branchToRemoveId;
    
                // Check if the branch to remove exists in the array
                if (currentBranches.includes(branchToRemove)) {
                    const branchRef = doc(db, "branches", branchToRemove);
                    const branchDoc = await getDoc(branchRef);
    
                    if (branchDoc.exists()) {
                        const branchData = branchDoc.data();
    
                        // Check if branchDepartments array is empty
                        if (branchData.branchDepartments && branchData.branchDepartments.length > 0) {
                            alert(t.alertDeleteAllDepartmentsInBranch);
                            return; // Exit the function if the condition is not met
                        }
    
                        // Move the branch to the "deletedBranches" collection
                        await setDoc(doc(db, "deletedBranches", branchToRemove), {branchData, deletedAt: new Date() });
                        await deleteDoc(branchRef);
    
                        // Remove the branch from the company's branches array
                        const updatedBranches = currentBranches.filter(branchId => branchId !== branchToRemove);
                        await updateDoc(companyRef, { branches: updatedBranches });
    
                        // Update local state
                        const updatedCompanies = companies.map(company => {
                            if (company.id === popupState.selectedCompanyId) {
                                return { ...company, branches: updatedBranches };
                            }
                            return company;
                        });
                        setCompanies(updatedCompanies);
                    } else {
                        alert(t.alertBranchNotInCompany);
                    }
                }
            }
        } catch (error) {
            console.error("Error removing branch from company: ", error);
        }
        setPopupState({ ...popupState, showRemoveBranchPopup: false, branchToRemoveId: '' });
        window.location.reload();
    };
    



    const showRemoveDepartment = (companyId) => {
        setPopupState({
            ...popupState,
            selectedCompanyId: companyId,
            showRemoveUserPopup: false,
            showRemoveAdminPopup: false,
            showRemoveBranchPopup: false,
            showRemoveDepartmentPopup: true,
            showAddAdminPopup: false,
            showAddUserPopup: false,
            showAddBranchPopup: false,
            showAddDepartmentPopup: false
        });
        setEditingCompany(null);
        setShowCreateForm(null);
    };
    
    const handleRemoveDepartmentFromCompany = async (e,selectedCompanyId) => {
        e.preventDefault();
        if (!window.confirm(t.deleteDepartmentmessage)) {
            return;
        }
    
        try {
            const companyRef = doc(db, "companies", popupState.selectedCompanyId);
            const companyDoc = await getDoc(companyRef);
    
            if (companyDoc.exists()) {
                const currentDepartments = companyDoc.data().mainDepartments;
                const departmentToRemove = popupState.departmentToRemoveId;
    
                // Check if the department to remove exists in the array
                if (currentDepartments.includes(departmentToRemove)) {
                    const updatedDepartments = currentDepartments.filter(departmentId => departmentId !== departmentToRemove);
                    await updateDoc(companyRef, { mainDepartments: updatedDepartments });
    
                    // Move the department to the "deletedDepartments" collection
                    const departmentRef = doc(db, "companyDepartments", departmentToRemove);
                    const departmentDoc = await getDoc(departmentRef);
                    if (departmentDoc.exists()) {
                    const departmentData = departmentDoc.data() ;
                        await setDoc(doc(db, "deletedCompanyDepartments", departmentToRemove), {departmentData, deletedAt: new Date() });
                        await deleteDoc(departmentRef);
                    }
    
                    // Update local state
                    const updatedCompanies = companies.map(company => {
                        if (company.id === popupState.selectedCompanyId) {
                            return { ...company, mainDepartments: updatedDepartments };
                        }
                        return company;
                    });
                    setCompanies(updatedCompanies);
                } else {
                    alert(t.alertdepartmentNotInCompany);
                }
            }
        } catch (error) {
            console.error("Error removing department from company: ", error);
        }
        setPopupState({ ...popupState, showRemoveDepartmentPopup: false, departmentToRemoveId: '' });
        window.location.reload();
    };

    const handleExitCompanyPopups = async ()=> {
        setPopupState({
            ...popupState,
            showAddUserPopup: false,
            showAddAdminPopup: false,
            showAddBranchPopup: false,
            showAddDepartmentPopup: false,
            showRemoveUserPopup: false,
            showRemoveAdminPopup: false,
            showRemoveBranchPopup: false,
            showRemoveDepartmentPopup: false
        });
    };
    const renderList = (items) => {
        if (items.length > 0) {
            return (
                <div className="table-main-container">
                    <h2>{t.manageCompaniesText}</h2>
                    <button className='button-add' onClick={startAddingCompany}>{t.createCompany}</button>
                    <table className="table-container">
                        <thead>
                            <tr>
                                <th> {t.companyLogo} </th>
                                <th> {t.companyUID} </th>
                                <th> {t.companyName} </th>
                                <th> {t.companyEmail} </th>
                                <th> {t.street} </th>
                                <th> {t.houseNumber} </th>
                                <th> {t.postalCode} </th>
                                <th> {t.city} </th>
                                <th> {t.state} </th>
                                <th> {t.country} </th>
                                <th> {t.companyPhoneNumber} </th>
                                <th> {t.companyWebsite} </th>
                                <th> {t.companyOfficialRegistrationNumber} </th>
                                <th> {t.editCompany} </th>
                                <th> {t.companyUsers} </th>
                                <th> {t.addUser} </th>
                                <th> {t.removeUser} </th>
                                <th> {t.companyAdmins} </th>
                                <th> {t.addAdmin} </th>
                                <th> {t.removeAdmin} </th>
                                <th> {t.companyBranches} </th>
                                <th> {t.addBranch} </th>
                                <th> {t.removeBranch} </th>
                                <th> {t.companyDepartments} </th>
                                <th> {t.addDepartment} </th>
                                <th> {t.removeDepartment} </th>
                                <th> {t.companyOfficialRegistrationDocument} </th>
                                <th> {t.companyIdentityColor} </th>
                                
                                <th> {t.leave} </th>
                                <th> {t.delete} </th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (
                                <tr key={item.id} >
                                    <td>{item.companyLogo ? (<img src={item.companyLogo} alt="Company Logo" style={{ width: '50px', height: '50px' }} />) : (<img src={VerifiedIcon} alt="No Logo" style={{ width: '50px', height: '50px' }} />)}</td>
                                    <td>{item.companyUID}</td>
                                    <td>{item.companyName}</td>
                                    <td>{item.companyEmail}</td>
                                    <td>{item.street}</td>
                                    <td>{item.houseNumber}</td>
                                    <td>{item.postalCode}</td>
                                    <td>{item.city}</td>
                                    <td>{item.state}</td>
                                    <td>{item.country}</td>
                                    <td>{item.companyPhoneNumber}</td>
                                    <td>{item.companyWebsite}</td>
                                    <td>{item.companyOfficialRegistrationNumber}</td>
                                    {item.companyAdmins && item.companyAdmins.includes(auth.currentUser.uid) ? (
                                        <>
                                            <td><button className='button-edit' onClick={() => startEditCompany(item.id)}>{t.editCompany}</button></td>
                                            <td><ArrayDisplay arrayData={item.companyUsers || []} /></td>
                                            <td><button className='button-add' onClick={() => showAddUser(item.id)}>{t.addUser}</button></td>
                                            <td><button className='button-remove' onClick={() => showRemoveUser(item.id)}>{t.removeUser}</button></td>
                                            <td><ArrayDisplay arrayData={item.companyAdmins || []} /></td>
                                            <td><button className='button-add' onClick={() => showAddAdmin(item.id)}>{t.addAdmin}</button></td>
                                            <td><button className='button-remove' onClick={() => showRemoveAdmin(item.id)}>{t.removeAdmin}</button></td>
                                            <td><ArrayDisplay arrayData={item.branches || []} /></td>
                                            <td><button className='button-add' onClick={() => showAddBranch(item.id)}>{t.addBranch}</button></td>
                                            <td><button className='button-remove' onClick={() => showRemoveBranch(item.id)}>{t.removeBranch}</button></td>
                                            <td><ArrayDisplay arrayData={item.mainDepartments || []} /></td>
                                            <td><button className='button-add' onClick={() => showAddDepartment(item.id)}>{t.addDepartment}</button></td>
                                            <td><button className='button-remove' onClick={() => showRemoveDepartment(item.id)}>{t.removeDepartment}</button></td>
                                            <td>{item.companyOfficialRegistrationDocument ? ( <a href={item.companyOfficialRegistrationDocument} target="_blank" rel="noopener noreferrer"> {item.companyOfficialRegistrationDocument} </a>    ) : (  'No document available' )}</td>
                                            <td>
                                                <div style={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: item.companyIdentityColor, margin: '0 auto' }}></div>
                                            </td>
                                            <td><button className='button-leave' onClick={() => handleLeaveAdminCompany(item.id)}>{t.leave}</button></td>
                                            <td><button className='button-delete' onClick={() => handleDeleteCompany(item.id)}>{t.delete}</button></td>
                                        </>
                                    ) : (
                                        <>
                                            <td></td> {/* Empty cell for edit button */}
                                            <td><ArrayDisplay arrayData={item.companyUsers || []} /></td>
                                            <td></td> {/* Empty cell for add user button */}
                                            <td></td> {/* Empty cell for remove user button */}
                                            <td><ArrayDisplay arrayData={item.companyAdmins || []} /></td>
                                            <td></td> {/* Empty cell for add admin button */}
                                            <td></td> {/* Empty cell for remove admin button */}
                                            <td><ArrayDisplay arrayData={item.branches || []} /></td>
                                            <td></td> {/* Empty cell for add branch button */}
                                            <td></td> {/* Empty cell for remove branch button */}
                                            <td><ArrayDisplay arrayData={item.mainDepartments || []} /></td>
                                            <td></td> {/* Empty cell for add department button */}
                                            <td></td> {/* Empty cell for remove department button */}
                                            <td>{item.companyOfficialRegistrationDocument ? (<a href={item.companyOfficialRegistrationDocument} target="_blank" rel="noopener noreferrer">{t.viewDocument}</a>) : (t.noDocument)}</td>
                                            <td>
                                                <div style={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: item.companyIdentityColor, margin: '0 auto' }}></div>
                                            </td>
                                            <td><button className='button-leave' onClick={() => handleLeaveUserCompany(item.id)}>{t.leave}</button></td>
                                            <td></td> {/* Empty cell for delete button */}
                                        </>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {showCreateForm && (
                        <form onSubmit={handleCreateCompany} className="popup">
                            <div><button type="button" onClick={handleExitCreatingCompany} className="button-exit">X</button></div>
                            <label htmlFor="companyName">{t.companyName}</label>
                            <input type="text" name="companyName" id="companyName" onChange={(e) => setNewCompanyData({ ...newCompanyData, companyName: e.target.value })} required />
                            <label htmlFor="companyEmail">{t.companyEmail}</label>
                            <input type="email" name="companyEmail" id="companyEmail" onChange={(e) => setNewCompanyData({ ...newCompanyData, companyEmail: e.target.value })} required />
                            <label htmlFor="street">{t.street}</label>
                            <input type="text" name="street" id="street" onChange={(e) => setNewCompanyData({ ...newCompanyData, street: e.target.value })} required />
                            <label htmlFor="houseNumber">{t.houseNumber}</label>
                            <input type="text" name="houseNumber" id="houseNumber" onChange={(e) => setNewCompanyData({ ...newCompanyData, houseNumber: e.target.value })} required />
                            <label htmlFor="postalCode">{t.postalCode}</label>
                            <input type="text" name="postalCode" id="postalCode" onChange={(e) => setNewCompanyData({ ...newCompanyData, postalCode: e.target.value })} required />
                            <label htmlFor="city">{t.city}</label>
                            <input type="text" name="city" id="city" onChange={(e) => setNewCompanyData({ ...newCompanyData, city: e.target.value })} required />
                            <label htmlFor="state">{t.state}</label>
                            <input type="text" name="state" id="state" onChange={(e) => setNewCompanyData({ ...newCompanyData, state: e.target.value })} required />
                            <label htmlFor="country">{t.country}</label>
                            <input type="text" name="country" id="country" onChange={(e) => setNewCompanyData({ ...newCompanyData, country: e.target.value })} required />
                            <label htmlFor="companyPhoneNumber">{t.companyPhoneNumber}</label>
                            <input type="tel" name="companyPhoneNumber" id="companyPhoneNumber" onChange={(e) => setNewCompanyData({ ...newCompanyData, companyPhoneNumber: e.target.value })} required />
                            <label htmlFor="companyWebsite">{t.companyWebsite}</label>
                            <input type="text" name="companyWebsite" id="companyWebsite" onChange={(e) => setNewCompanyData({ ...newCompanyData, companyWebsite: e.target.value })} required />
                            <label htmlFor="companyOfficialRegistrationNumber">{t.companyOfficialRegistrationNumber}</label>
                            <input type="text" name="companyOfficialRegistrationNumber" id="companyOfficialRegistrationNumber" onChange={(e) => setNewCompanyData({ ...newCompanyData, companyOfficialRegistrationNumber: e.target.value })} required />
                            <label htmlFor="companyOfficialRegistrationDocument">{t.companyOfficialRegistrationDocument}</label>
                            <input type="file" name="companyOfficialRegistrationDocument" id="companyOfficialRegistrationDocument" onChange={handleFileChange} required accept=".pdf" />
                            <label htmlFor="companyLogo">{t.companyLogo}</label>
                            <input type="file" name="companyLogo" id="companyLogo" onChange={handleLogoChange} accept=".png, .jpg, .jpeg" />
                            <label htmlFor="companyIdentityColor">{t.companyIdentityColor}</label>
                            <input type="color" name="companyIdentityColor" id="companyIdentityColor" defaultValue="#30acfd"  onChange={(e) => setNewCompanyData({ ...newCompanyData, companyIdentityColor: e.target.value })} required />
                            <button type="submit" className='button-submit'>{t.submit}</button>
                        </form>
                    )}
                    {editingCompany && (
                        <form onSubmit={(e) => handleEditCompany(e, editingCompany)} className="popup">
                            <div><button type="button" onClick={handleExitEditingCompany} className="button-exit">X</button></div>
                            <label htmlFor="companyName">{t.companyName}</label>
                            <input type="text" name="companyName" id="companyName" value={editingCompanyData.companyName} onChange={(e) => setEditingCompanyData({ ...editingCompanyData, companyName: e.target.value })} required />
                            <label htmlFor="email">{t.companyEmail}</label>
                            <input type="email" name="email" id="email" value={editingCompanyData.companyEmail} onChange={(e) => setEditingCompanyData({ ...editingCompanyData, companyEmail: e.target.value })} required />
                            <label htmlFor="street">{t.street}</label>
                            <input type="text" name="street" id="street" value={editingCompanyData.street} onChange={(e) => setEditingCompanyData({ ...editingCompanyData, street: e.target.value })} required />
                            <label htmlFor="houseNumber">{t.houseNumber}</label>
                            <input type="text" name="houseNumber" id="houseNumber" value={editingCompanyData.houseNumber} onChange={(e) => setEditingCompanyData({ ...editingCompanyData, houseNumber: e.target.value })} required />
                            <label htmlFor="postalCode">{t.postalCode}</label>
                            <input type="text" name="postalCode" id="postalCode" value={editingCompanyData.postalCode} onChange={(e) => setEditingCompanyData({ ...editingCompanyData, postalCode: e.target.value })} required />
                            <label htmlFor="city">{t.city}</label>
                            <input type="text" name="city" id="city" value={editingCompanyData.city} onChange={(e) => setEditingCompanyData({ ...editingCompanyData, city: e.target.value })} required />
                            <label htmlFor="state">{t.state}</label>
                            <input type="text" name="state" id="state" value={editingCompanyData.state} onChange={(e) => setEditingCompanyData({ ...editingCompanyData, state: e.target.value })} required />
                            <label htmlFor="country">{t.country}</label>
                            <input type="text" name="country" id="country" value={editingCompanyData.country} onChange={(e) => setEditingCompanyData({ ...editingCompanyData, country: e.target.value })} required />
                            <label htmlFor="companyPhoneNumber">{t.companyPhoneNumber}</label>
                            <input type="tel" name="companyPhoneNumber" id="companyPhoneNumber" value={editingCompanyData.companyPhoneNumber} onChange={(e) => setEditingCompanyData({ ...editingCompanyData, companyPhoneNumber: e.target.value })} required />
                            <label htmlFor="companyWebsite">{t.companyWebsite}</label>
                            <input type="text" name="companyWebsite" id="companyWebsite" value={editingCompanyData.companyWebsite} onChange={(e) => setEditingCompanyData({ ...editingCompanyData, companyWebsite: e.target.value })} required />
                            <label htmlFor="companyLogo">{t.companyLogo}</label>
                            <input type="file" name="companyLogo" id="companyLogo" onChange={handleLogoChange} accept=".png, .jpg, .jpeg" />
                            <label htmlFor="companyIdentityColor">{t.companyIdentityColor}</label>
                            <input type="color" name="companyIdentityColor" id="companyIdentityColor" value={editingCompanyData.companyIdentityColor} onChange={(e) => setEditingCompanyData({ ...editingCompanyData, companyIdentityColor: e.target.value })} required />
                            <button type="submit" className='button-submit'>{t.saveChanges}</button>
                        </form>
                    )}
                    {popupState.showAddUserPopup && (
                        <form onSubmit={(e) => handleAddUserToCompany(e, popupState.selectedCompanyId)} className="popup">
                            <div><button type="button" onClick={handleExitCompanyPopups} className="button-exit">X</button></div>
                            <input type="text" value={popupState.newUserId} onChange={(e) => setPopupState({ ...popupState, newUserId: e.target.value })} placeholder={t.enterUserID} required />
                            <button type='submit' className='button-add'>{t.addUser}</button>
                        </form>
                    )}
                    {popupState.showRemoveUserPopup && (
                        <form onSubmit={(e) => handleRemoveUserFromCompany(e, popupState.selectedCompanyId)} className="popup">
                            <div><button type="button" onClick={handleExitCompanyPopups} className="button-exit">X</button></div>
                            <input type="text" value={popupState.userToRemoveId} onChange={(e) => setPopupState({ ...popupState, userToRemoveId: e.target.value })} placeholder={t.enterUserID} required />
                            <button type='submit' className='button-remove'>{t.removeUser}</button>
                        </form>
                    )}
                    {popupState.showAddAdminPopup && (
                        <form onSubmit={(e) => handleAddAdminToCompany(e, popupState.selectedCompanyId)} className="popup">
                            <div><button type="button" onClick={handleExitCompanyPopups} className="button-exit">X</button></div>
                            <input type="text" value={popupState.newAdminId} onChange={(e) => setPopupState({ ...popupState, newAdminId: e.target.value })} placeholder={t.enterAdminID} required />
                            <button type='submit' className='button-add'>{t.addAdmin}</button>
                        </form>
                    )}
                    {popupState.showRemoveAdminPopup && (
                        <form onSubmit={(e) => handleRemoveAdminFromCompany(e, popupState.selectedCompanyId)} className="popup">
                            <div><button type="button" onClick={handleExitCompanyPopups} className="button-exit">X</button></div>
                            <input type="text" value={popupState.adminToRemoveId} onChange={(e) => setPopupState({ ...popupState, adminToRemoveId: e.target.value })} placeholder={t.enterAdminID} required />
                            <button type='submit' className='button-remove'>{t.removeAdmin}</button>
                        </form>
                    )}
                    {popupState.showAddBranchPopup && (
                        <form onSubmit={(e) => handleAddBranchToCompany(e, popupState.selectedCompanyId)} className="popup">
                            <div><button type="button" onClick={handleExitCompanyPopups} className="button-exit">X</button></div>
                            <label htmlFor="branchName">{t.branchName}</label>
                            <input type="text" value={newBranchData.branchName} onChange={(e) => setNewBranchData({ ...newBranchData, branchName: e.target.value })} placeholder={t.enterBranchName} required />
                            <label htmlFor="email">{t.branchEmail}</label>
                            <input type="email" value={newBranchData.branchEmail} onChange={(e) => setNewBranchData({ ...newBranchData, branchEmail: e.target.value })} placeholder={t.enterBranchEmail} required />
                            <label htmlFor="street">{t.street}</label>
                            <input type="text" value={newBranchData.street} onChange={(e) => setNewBranchData({ ...newBranchData, street: e.target.value })} placeholder={t.enterStreet} required />
                            <label htmlFor="houseNumber">{t.houseNumber}</label>
                            <input type="text" value={newBranchData.houseNumber} onChange={(e) => setNewBranchData({ ...newBranchData, houseNumber: e.target.value })} placeholder={t.enterHouseNumber} required />
                            <label htmlFor="postalCode">{t.postalCode}</label>
                            <input type="text" value={newBranchData.postalCode} onChange={(e) => setNewBranchData({ ...newBranchData, postalCode: e.target.value })} placeholder={t.enterPostalCode} required />
                            <label htmlFor="city">{t.city}</label>
                            <input type="text" value={newBranchData.city} onChange={(e) => setNewBranchData({ ...newBranchData, city: e.target.value })} placeholder={t.enterCity} required />
                            <label htmlFor="state">{t.state}</label>
                            <input type="text" value={newBranchData.state} onChange={(e) => setNewBranchData({ ...newBranchData, state: e.target.value })} placeholder={t.enterState} required />
                            <label htmlFor="country">{t.country}</label>
                            <input type="text" value={newBranchData.country} onChange={(e) => setNewBranchData({ ...newBranchData, country: e.target.value })} placeholder={t.enterCountry} required />
                            <label htmlFor="branchPhoneNumber">{t.branchPhoneNumber}</label>
                            <input type="tel" value={newBranchData.branchPhoneNumber} onChange={(e) => setNewBranchData({ ...newBranchData, branchPhoneNumber: e.target.value })} placeholder={t.enterBranchPhoneNumber} required />
                            <button type='submit' className='button-add'>{t.addBranch}</button>
                        </form>
                    )}
                    {popupState.showRemoveBranchPopup && (
                        <form onSubmit={(e) => handleRemoveBranchFromCompany(e, popupState.selectedCompanyId)} className="popup">
                            <div><button type="button" onClick={handleExitCompanyPopups} className="button-exit">X</button></div>
                            <input type="text" value={popupState.branchToRemoveId} onChange={(e) => setPopupState({ ...popupState, branchToRemoveId: e.target.value })} placeholder={t.enterBranchID} required />
                            <button type='submit' className='button-remove'>{t.removeBranch}</button>
                        </form>
                    )}
                    {popupState.showAddDepartmentPopup && (
                        <form onSubmit={(e) => handleAddDepartmentToCompany(e, popupState.selectedCompanyId)} className="popup">
                            <div><button type="button" onClick={handleExitCompanyPopups} className="button-exit">X</button></div>
                            <label htmlFor="departmentName">{t.departmentName}</label>
                            <input type="text" value={newDepartmentData.departmentName} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, departmentName: e.target.value })} placeholder={t.enterDepartmentName} required />
                            <label htmlFor="email">{t.departmentEmail}</label>
                            <input type="email" value={newDepartmentData.departmentEmail} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, departmentEmail: e.target.value })} placeholder={t.enterDepartmentEmail} required />
                            <label htmlFor="street">{t.street}</label>
                            <input type="text" value={newDepartmentData.street} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, street: e.target.value })} placeholder={t.enterStreet} required />
                            <label htmlFor="houseNumber">{t.houseNumber}</label>
                            <input type="text" value={newDepartmentData.houseNumber} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, houseNumber: e.target.value })} placeholder={t.enterHouseNumber} required />
                            <label htmlFor="postalCode">{t.postalCode}</label>
                            <input type="text" value={newDepartmentData.postalCode} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, postalCode: e.target.value })} placeholder={t.enterPostalCode} required />
                            <label htmlFor="city">{t.city}</label>
                            <input type="text" value={newDepartmentData.city} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, city: e.target.value })} placeholder={t.enterCity} required />
                            <label htmlFor="state">{t.state}</label>
                            <input type="text" value={newDepartmentData.state} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, state: e.target.value })} placeholder={t.enterState} required />
                            <label htmlFor="country">{t.country}</label>
                            <input type="text" value={newDepartmentData.country} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, country: e.target.value })} placeholder={t.enterCountry} required />
                            <label htmlFor="departmentPhoneNumber">{t.departmentPhoneNumber}</label>
                            <input type="tel" value={newDepartmentData.departmentPhoneNumber} onChange={(e) => setNewDepartmentData({ ...newDepartmentData, departmentPhoneNumber: e.target.value })} placeholder={t.enterDepartmentPhoneNumber} required />
                            <button type='submit' className='button-add'>{t.addDepartment}</button>
                        </form>
                    )}
                    {popupState.showRemoveDepartmentPopup && (
                        <form onSubmit={(e) => handleRemoveDepartmentFromCompany(e, popupState.selectedCompanyId)} className="popup">
                            <div><button type="button" onClick={handleExitCompanyPopups} className="button-exit">X</button></div>
                            <input type="text" value={popupState.departmentToRemoveId} onChange={(e) => setPopupState({ ...popupState, departmentToRemoveId: e.target.value })} placeholder={t.enterDepartmentID} required />
                            <button type='submit' className='button-remove'>{t.removeDepartment}</button>
                        </form>
                    )}
                </div>
            );
        } else {
            return (
                <div className='table-main-container'>
                    <h2>{t.manageCompaniesText}</h2>
                    <button className='button-add' onClick={startAddingCompany}>{t.createCompany}</button>
                    <p>{t.emptyList}</p>
                </div>
            );
        }
    };

    return (
        <div className="table-main-container">
            {renderList(companies)}
        </div>
    );
};

export default ManageCompanies;