import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../../styles/global.css';
import NavigationSideBar from '../dashboard/NavigationSideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import ideenwettbewerbRLPBadge2024 from '../../assets/images/IW2024_Startup Sonderpreistraeger.png';
import logihashLogo from '../../assets/images/LogiHash_Logo.png';
import aiPhotoOfLogiHash from '../../assets/images/AI-Photo-of-LogiHash.png';


const HomePage = () => {
  const navigate = useNavigate();
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].home;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoggedIn(!!user);
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handlePurchaseClick = () => {
    navigate('/purchase-qr-code');
  };

  return (

    <div className="white-page-content">
          <div>    { isLoggedIn && <NavigationSideBar/>}

    <div className="home-page">
      <h1>{t.welcomeMessage}</h1>
      <img src={logihashLogo} alt="LogiHash Logo" className="logihash-badge" />
      <h2>{t.logiHashPrototypeMessage}</h2>
      {/*<h3>{t.bodyTextHome}</h3>*/}
      <div className="button-container">
      { !isLoggedIn && <button className= 'button-submit' onClick={handleLoginClick}>{t.gotologin}</button>}
      { isLoggedIn && <button className= 'button-submit' onClick={handlePurchaseClick}>{t.gotoOnlineShop} <FontAwesomeIcon icon={faShoppingCart} /></button>}
      </div>
      <br />
      <div >
      <img src={aiPhotoOfLogiHash} alt="LogiHash Concept" className="logihash-ai-design" />
        {/* Competition badge with link */}
        <h2 className="happy-move">{t.IdeenwettbewerbRLP2024Winners}</h2>

        <a href="https://www.ideenwettbewerb-rlp.de/logihash" target="_blank" rel="noopener noreferrer">
          <img src={ideenwettbewerbRLPBadge2024} alt="Competition Winner Badge" className="competition-badge" />
        </a>
        </div>
    </div>

    </div>
    </div>

  );
};

export default HomePage;
