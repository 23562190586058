import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import QRCode from 'qrcode.react';
import db, { auth } from '../../backend/firebase/firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import '../../styles/global.css';


const ViewQRCodeIdentity = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].dashboard;
  const [selectedIdentity, setSelectedIdentity] = useState('');
  const [qrCodeData, setQRCodeData] = useState('');
  const [identityOptions, setIdentityOptions] = useState([]);

  // Fetch user UID
  const userUID = auth.currentUser.uid;

  useEffect(() => {
    fetchAllIdentities();
  }, []);

  useEffect(() => {
    const selectedOption = identityOptions.find(option => option.label === selectedIdentity);
    setQRCodeData(selectedOption ? selectedOption.value : '');
  }, [selectedIdentity, identityOptions]);

  const fetchAllIdentities = async () => {
    let identities = [];

    // Fetch from users collection
    const userData = await fetchUserData(userUID);
    if (userData) {
      identities.push({ label: `${userData.firstName} ${userData.lastName}`, value: userData.uid, type: t.personalIdentity });
    }

    // Fetch from other collections
    await Promise.all([
      fetchCollectionData('companies', 'companyUsers', 'companyAdmins', 'companyName', 'companyUID', t.companyIdentity),
      fetchCollectionData('branches', 'branchUsers', 'branchAdmins', 'branchName', 'branchUID', t.branchIdentity),
      fetchCollectionData('companyDepartments', 'departmentUsers', 'departmentAdmins', 'departmentName', 'departmentUID', t.departmentIdentity),
      fetchCollectionData('branchDepartments', 'departmentUsers', 'departmentAdmins', 'departmentName', 'departmentUID',  t.departmentIdentity)
    ]).then((results) => {
    results.forEach(result => identities = identities.concat(result));
    setIdentityOptions(identities);
    if (identities.length > 0) {
    setSelectedIdentity(identities[0].label);
    }
    });
    };
    
    const fetchCollectionData = async (collectionName, userField, adminField, nameField, uidField, identityType) => {
    const qUser = query(collection(db, collectionName), where(userField, "array-contains", userUID));
    const qAdmin = query(collection(db, collectionName), where(adminField, "array-contains", userUID));
    const userSnapshot = await getDocs(qUser);
    const adminSnapshot = await getDocs(qAdmin);

    const identities = [];
userSnapshot.docs.forEach(doc => {
  const data = doc.data();
  identities.push({ label: data[nameField], value: data[uidField], type: identityType });
});
adminSnapshot.docs.forEach(doc => {
  const data = doc.data();
  if (!identities.some(identity => identity.value === data[uidField])) {
    identities.push({ label: data[nameField], value: data[uidField], type: identityType });
  }
});
return identities;
};

const fetchUserData = async (uid) => {
  const q = query(collection(db, "users"), where("uid", "==", uid));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => doc.data())[0];
  };
  
  const renderIDQRCode = () => {

    return (
      <div className="qr-code-container">
          <div id="qrCodeToPrint">
            <QRCode value={qrCodeData} size={128} level={"H"} />
            <div className="qr-code-value">{qrCodeData}</div>
            <div className="qr-code-text">{t.LogiHashQRCode}</div>

          </div>
      </div>
    );
  };
  
  const renderQRCodeIdentitySection = () => {
  return (
  <div>
  <div> <h2>{t.personalIdentityInfoMessage}</h2> </div>
  <div>
  {renderIDQRCode()}
  <select onChange={(e) => setSelectedIdentity(e.target.value)} value={selectedIdentity} className="identity-select">
  {identityOptions.map(option => (
  <option key={option.value} value={option.label}>
  {option.label} ({option.type})
  </option>
  ))}
  </select>
  </div>
  </div>
  );
  };
  
  return (
  <div className="qr-code-identity">
  {renderQRCodeIdentitySection()}
  </div>
  );
  };
  
  export default ViewQRCodeIdentity;
