import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext'; 
import '../../../styles/global.css';
const MobileAppDescription = () => {
    const { language, translations } = useContext(LanguageContext);
    const t = translations[language].mobileAppDescription;
  return (
    <div className="white-page-content">
    <div className="mobile-app-description">
      <h1>{t.title}</h1>
      <p>{t.content}</p>
    </div>
    </div>
  );
};

export default MobileAppDescription;
