import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import RegistrationForm from './RegistrationForm';
import '../../styles/global.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const RegistrationPage = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].registration;
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is logged in, redirect away from registration page
        navigate('/dashboard');
      }
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, [navigate]);

  return (
    <div className='white-page-content'>
    <div className="registration-page">
      <h1>{t.registrationMessage}</h1>
      <RegistrationForm navigate={navigate} />
    </div>
    </div>
  );
};

export default RegistrationPage;
