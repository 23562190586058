import React, { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import '../../styles/global.css';

const LanguageSwitcher = () => {
  const { language, switchLanguage } = useContext(LanguageContext);

  return (
    <select 
      onChange={(e) => switchLanguage(e.target.value)} 
      className="language-switcher" 
      value={language}
    >
      <option value="en">🇺🇸 EN</option>
      <option value="de">🇩🇪 DE</option>
    </select>
  );
};

export default LanguageSwitcher;